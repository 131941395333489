import './App.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Pages/Login';
import TermsOfUse from './Pages/TermsOfUse';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedComponent from './Pages/ProtectedComponent';

function App() {
  const routePageNames = {
    '/project': 'Projects',
    '/dashboard': 'Dashboard',
    '/file-upload':'Upload File',
    '/terms_of_use':'Terms of Use',
  };
  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  const { pathname } = window.location;
  const currentPageName = routePageNames[pathname] || 'Unknown Page';

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<ProtectedComponent />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
      </Routes>

      {/* {!isLoginPage && <Header currentPageName={currentPageName}  />}
      <Routes>
        <Route path="/project" element={<Project />} />
        <Route path="/" element={<Login />} />
        <Route path="/file-upload" element={<FileUpload/>} />
      </Routes>
      {!isLoginPage && <Footer />} */}

    </div>
    
  );
}

export default App;
