import {React} from 'react';
import { useEffect, useState } from 'react';

import axios from 'axios';
import '../css/projects.css';
// import {CardData } from '../utils/cardsData.js'
import ProjectFilter from '../Component/ProjectFilter'
import { siteCall, queryParams } from '../utils/WebCall'; // Relative import
import { baseUrl } from "../utils/urls";
import '../css/FileUpload.css';

function Project() {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      const config = {
        url: `${baseUrl}/user/projects`,
        method: 'GET',
        successCallback: (response) => {
          setData(response.data);
        },
        errorCallback: (error) => {
          console.error('Error fetching data:', error);
        }
      };

      try {
        await siteCall(config);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const handleProjectClick = (projectId) => {
    const jwt = queryParams('jwt');
    window.location.href = `/dashboard/${projectId}?jwt=${jwt}`; // Redirect to dashboard route
  };


  return (
    <div className="outer-container container">
      <ProjectFilter />
      <div className="table-list">
        <table className="table borderless mt-3">
          <thead className='table-head'>
            <tr>
              <th scope="col">Project Name</th>
              <th scope="col">Impact Score</th>
              <th scope="col">SDGs Advanced</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((project, index) => (
              <tr key={index} onClick={() => handleProjectClick(project.project_id)}>
                <td>{project.project_name}</td>
                <td>{project.impact_score}</td>
                <td>{project.sdgs}</td>
                <td className={`status-${project.status.toLowerCase()}`}>
                  <u>{project.status}</u>
                </td>
                <td><i className='fa fa-trash'></i></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

}


export default Project;