import React from 'react'

import FileUpload from './Pages/FileUpload'
import Project from './Pages/Project'
import Dashboard from './Pages/Dashboard'
import ConsolidatedDashboard from './Pages/ConsolidatedDashboard'


const routes = [
  { path: '/', exact: true, name: 'FileUpload', element: <FileUpload /> },
  { path: '/file-upload', name: 'FileUpload', element: <FileUpload /> },
  { path: '/projects', name: 'Project', element: <Project /> },
  { path: '/dashboard/:projectId', name: 'Dashboard', element: <Dashboard /> },
  { path: '/consolidated-dashboard', name: 'ConsolidatedDashboard', element: <ConsolidatedDashboard /> },
//   { path: '/dashboard', name: 'Dashboard', element: <Dashboard /> },
]

export default routes
