import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import '../css/ImpactScorePortfolioMetrics.css'

const ImpactScorePortfolioMetrics = (props) => {
  const { impactScoreDistribution } = props;
    const data = [
        {
          label: "Average interest rate",
          value: "45.47%",
          popoverTitle: "Average interest rate",
          popoverContent: "Average interest rate paid across loans in the portfolio.",
        },
        {
          label: "Average loan tenor",
          value: "13 months",
          popoverTitle: "Average loan tenor",
          popoverContent: "Average term of loans.",
        },
        {
          label: "Default rate",
          value: "0%",
          popoverTitle: "Default rate",
          popoverContent: "Proportion of loans that have been written off.",
        },
      ];
 
  return (
      <div>
          <div className="row">
          <div className="col-xl-12 col-md-12 custom-padding-right-8">
            <div className="card card-shadow None">
              <div className="card-header border-0">
                <div className="custom-title-wrap bar-primary">
                  <div className="custom-title">
                      <h6><span className="impactscoreportfoliometrics-title">Beneficiary Score</span>
                      <OverlayTrigger
                        placement="auto" trigger="click" rootClose
                        overlay={
                          <Popover>
                              <Popover.Body>
                            Beneficiary Impact Score reflects the potential impact that can be created by measuring how well the portfolio is reaching it's intended target beneficiaries.
                              </Popover.Body>
                            </Popover>
                        }>
                        <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>
                        </OverlayTrigger>
                          </h6>
                  </div>
                </div>
              </div>
              <div className="card-body portfolio-metrics-body" style={{padding:'0px'}}>
                <div className="statistics-wrapper">
                  <h4 className="mb-2 weight700 card-value" id="beneficiary_impact_score" style={{textAlign:'center',paddingTop:'1%'}}>{impactScoreDistribution?.beneficiary_indicator_score?.toFixed(2)}</h4>
              </div>
            </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 custom-padding-right-8">
            <div className="card card-shadow None">
              <div className="card-header border-0">
                <div className="custom-title-wrap bar-primary">
                  <div className="custom-title">
                      <h6><span className="impactscoreportfoliometrics-title">Investment Contribution Score</span>
                      <OverlayTrigger
                        placement="auto" trigger="click" rootClose
                        overlay={
                          <Popover>
                              <Popover.Body>
                              Investment Contribution indicates to what extent the investment or portfolio contributes financially to the intended impacts being sought i.e. it reflects the additionality brought by the investment, without which would the impact occur to the same extent.
                              </Popover.Body>
                            </Popover>
                        }>
                        <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>
                        </OverlayTrigger>
                          </h6>
                  </div>
                </div>
              </div>
              <div className="card-body portfolio-metrics-body" style={{padding:'0px'}}>
                <div className="statistics-wrapper">
                  <h4 className="mb-2 weight700 card-value" id="beneficiary_impact_score" style={{textAlign:'center',paddingTop:'1%'}}>{impactScoreDistribution?.contribution_indicator_score?.toFixed(2)}</h4>
                </div>
            </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 custom-padding-right-8">
            <div className="card card-shadow None">
              <div className="card-header border-0">
                <div className="custom-title-wrap bar-primary">
                  <div className="custom-title">
                      <h6><span className="impactscoreportfoliometrics-title">Risk Score</span>
                      <OverlayTrigger
                        placement="auto" trigger="click" rootClose
                        overlay={
                          <Popover>
                              <Popover.Body>
                              Risk Score looks at the various risk to achieving the desired impact, such as alignment risk, execution risk, efficiency risk and evidence risk.The higher the score, the lower the probability of said risk happening.
                              </Popover.Body>
                            </Popover>
                        }>
                        <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>
                        </OverlayTrigger>
                          </h6>
                  </div>
                </div>
              </div>
              <div className="card-body portfolio-metrics-body" style={{padding:'0px'}}>
                <div className="statistics-wrapper">
                  <h4 className="weight700 card-value" id="beneficiary_impact_score" style={{textAlign:'center',paddingTop:'1%'}}>{impactScoreDistribution?.risk_indicator_score?.toFixed(2)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
  );
};

export default ImpactScorePortfolioMetrics;

