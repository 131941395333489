import { baseUrl, DOWNLOAD_MCT_OUTPUT, DOWNLOAD_IMPACT_SCORE, DOWNLOAD_DISPERSED_IMPACT_SCORE } from "../utils/urls";
import { siteCall } from '../utils/WebCall';
import { downloadFile } from "../Services/CommonService";

export const downloadMCT = async({project_id, file_id = ''}) => {
    let searchParams = {project_id: project_id}
    if(file_id != ""){
      searchParams['file_id'] = file_id;
    }
    let params = new URLSearchParams(searchParams);
    const config = {
        url: `${baseUrl}${DOWNLOAD_MCT_OUTPUT}?${params}`,
        method: 'GET',
        'responseType': 'blob',
    };
    return await siteCall(config).then((response) => {
        let data = response.data;
        let fileName = `mct-${project_id}.csv`;
        downloadFile(data, fileName);
    });
}
  
export const downloadImpactScore = async({project_id, file_id = ''}) => {
    let searchParams = {project_id: project_id}
    if(file_id != ""){
        searchParams['file_id'] = file_id;
    }
    let params = new URLSearchParams(searchParams);
    const config = {
        url: `${baseUrl}${DOWNLOAD_IMPACT_SCORE}?${params}`,
        method: 'GET',
        'responseType': 'blob',
    };
    return await siteCall(config).then((response) => {
        let data = response.data;
        let fileName = `impact-score-${project_id}.csv`;
        downloadFile(data, fileName);
    });
}
  
export const downloadDImpactScore = async({project_id, file_id = ''}) => {
    let searchParams = {project_id: project_id}
    if(file_id != ""){
      searchParams['file_id'] = file_id;
    }
    let params = new URLSearchParams(searchParams);
    const config = {
        url: `${baseUrl}${DOWNLOAD_DISPERSED_IMPACT_SCORE}?${params}`,
        method: 'GET',
        'responseType': 'blob',
    };
    return await siteCall(config).then((response) => {
        let data = response.data;
        let fileName = `dimpact-score-${project_id}.csv`;
        downloadFile(data, fileName);
    });;
}