import React, { useEffect, useRef } from 'react';
import { create } from '@amcharts/amcharts4/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.addLicense("CH223343194");

const AmChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartRef1 = useRef(null);

  useEffect(() => {
    let chart = am4core.create(chartRef.current, am4charts.PieChart);
    am4core.useTheme(am4themes_animated);
    // Filter out data items with value 0
    chart.data = data.filter(item => item.value !== 0);

    var legendContainer = am4core.create(chartRef1.current, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.tooltipText = "{category}";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend();
    chart.responsive.enabled = true;
    chart.legend.itemContainers.template.paddingTop = 4;
    chart.legend.itemContainers.template.paddingBottom = 4;
    chart.legend.itemContainers.template.minWidth = 250;
    chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.wrap = true;
    chart.legend.contentAlign = "left";
    chart.legend.layout = "vertical";
    chart.legend.align = "left";
    chart.legend.textAlign = "end";
    chart.legend.maxWidth = 150;
    chart.legend.position = "right";
    chart.legend.fontSize = "12px";

    pieSeries.innerRadius = am4core.percent(60);
    pieSeries.slices.template.propertyFields.fill = "fill";
    pieSeries.slices.template.propertyFields.stroke = "stroke";
    chart.legend.parent = legendContainer;

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;

    var marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.strokeWidth = 0;
    marker.stroke = am4core.color("#ccc");

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.propertyFields.fill = "color";

    let label = chart.seriesContainer.createChild(am4core.Label);
    label.horizontalCenter = "top";
    label.verticalCenter = "top";
    label.fontSize = 24;
    pieSeries.tooltip.label.maxWidth = 220;
    pieSeries.tooltip.label.wrap = true;
    pieSeries.tooltip.label.fontSize = 14;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div className='row'>
      <div className='col-md-5' style={{ height: '195px' }}>
        <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>
      </div>
      <div className='col-md-7' style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
        <div ref={chartRef1}></div>
      </div>
    </div>
  );
}

export default AmChart;
