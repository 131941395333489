import { siteCall, queryParams } from '../utils/WebCall';
import * as url from "../utils/urls";

export const loginUser = async(params) => {
  return siteCall(
    {
      url: url.baseUrl + url.LOGIN_URL,
      method: 'POST',
      body: params
    }
  )
}

export const registerUser = async(params) => {
  return siteCall(
    {
      url: url.baseUrl + url.REGISTER_URL,
      method: 'POST',
      body: params
    }
  )
}

export const logoutUser = (navigate) => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  navigate("/login");
}

export const setLoggedInUser = (user, token) => {
  user.loginTime = new Date().toISOString()
  localStorage.setItem("accessToken", token);
  localStorage.setItem("user", JSON.stringify(user));
}

export const getLoggedInUser = () => {
  let user = localStorage.getItem("user");
  if (user){
    user = JSON.parse(user);
    let accessToken = localStorage.getItem("accessToken");
    user.accessToken = accessToken
    return user;
  }
  return null;
}

export const getToken = () => {
  let user = getLoggedInUser();
  if(user){
    return user.accessToken;
  }else{
    return queryParams('jwt');
  }
}