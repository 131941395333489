import { siteCall, queryParams } from '../utils/WebCall';
import * as url from "../utils/urls";


export const getUserProfile = async() => {
  return siteCall(
    {
      url: url.baseUrl + url.USER_PROFILE_URL,
      method: 'GET',
    }
  )
}