import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

const HorizontalBarChart = ({xTitle, yTitle,allData,barWidth,ManualLabels}) => {
  const data = {
    labels: ManualLabels,
    datasets: [
      {
        label: '',
        data: allData,
        backgroundColor: ['#b4b4b4','#162735','#437499'],
        borderColor: ['#b4b4b4','#162735','#437499'],
        borderWidth: 1,
        barPercentage: barWidth, 
        categoryPercentage: 0.6,
      },
    ], 
  };

  const options = {
    animation: {
        duration: 1500,
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y', // Set the index axis to y for horizontal bars
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Disable tooltips
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.dataset.data[context.dataIndex] + '%'; // Append percentage sign
            }
            return label;
          }
        }
      },
    },
    scales: {
      x: {
        grid: {
        //   drawOnChartArea: false,
          display: false, // Hide x-axis gridlines
        },
        title: {
          display: true,
          text: xTitle || '',
          font: {
            size: 11,
          },
        },
        stacked: true,
        ticks: {
          font: {
            size: 11, // Set font size for y-axis ticks
          },
            maxRotation: 0,
            minRotation: 0,
            fontSize: 11,
            stepSize: 10,
            callback: function(value, index, values) {
              return value.toFixed(1); // Display values with one decimal place
            }
            // padding:10,
        },
        beginAtZero: true,
        min: 0,
        max: 100,
      },
      y: {
        grid: {
          display: false, // Hide x-axis gridlines
        },
        title: {
          display: true,
          text: yTitle || '',
          font: {
            size: 11,
          },
        },
        ticks: {
          font: {
            size: 11, // Set font size for y-axis ticks
          },
          maxRotation: 0,
          minRotation: 0,
        },
        beginAtZero: true,
      },
    },
  };

  return(
    <div className='barchart-canvas' style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Bar data={data} options={options}/>
    </div>
  );
};

export default HorizontalBarChart;