import React from 'react'
import '../../../css/print.css';
import '../../../css/urj.css';

const URJDashboardTheory = ({ tabType }) => {
  return (
    <>
      <div className="row page-divide-before">
        <h6 className="mb-4 theory-print">
          <b>Impact Score & UN SDG Contribution</b>
        </h6>

        <h6 className="mb-4 theory-print">
          <b>Financial Inclusion Impact Framework</b>
        </h6>
        <p className="theory-print">
        The following impact framework has been used to assess the impact of an Investor’s debt impact investment in the sector of financial inclusion. The framework details the mapping to the IMP’s dimensions of impact and UN SDGs, and shows a sample of indicators and target outcomes used in measuring the impact created.
        </p>
      </div>

      <div className='row'>
        <div className="table-responsive">
          <table className="table impact-framework">
            <thead>
              <tr>
                <th>IMP Dimension</th>
                <th>Theme</th>
                <th>Sample Indicators</th>
                <th>Target Outcomes</th>
                <th>UN SDGs</th>
                <th>UN SDG Targets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>WHO</td>
                <td>Poverty level</td>
                <td>
                  Income level<br></br>Employment nature<br></br>Beneficiary
                  <br></br>
                </td>
                <td>
                  Increased income<br></br>Gender equality<br></br>
                </td>
                <td rowSpan={5} className="text-center">
                  <img
                    src="/sdg-images/sdg1.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg5.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg10.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                </td>
                <td className='border-bottom1'>1.1, 1.4</td>
              </tr>
              <tr>
                <td>HOW MUCH</td>
                <td>Social impact</td>
                <td>Level of reach</td>
                <td>Community impact</td>
                <td className='border-bottom2'>5.5, 5.7</td>
              </tr>
              <tr>
                <td>RISK</td>
                <td>Impact risk</td>
                <td>
                  Alignment risk<br></br>Execution risk<br></br>Evidence risk
                  <br></br>
                </td>
                <td>NA</td>
                <td className='border-bottom5'>10.2, 10.3, 10.9</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
     
      <div className='row'>
        <h6 className="theory-print">
          <b>GreenArc Impact Measurement & Management</b>
        </h6>
        <p className='theory-print'>
          Our impact measurement system has fully aligned with common market
          practices, including the SDG Impact Standards, the Impact Management
          Project's (IMP) five dimensions of impact, with key indicators selected
          from GIIN’s IRIS+ catalogue of metrics and GRI. GreenArc is a member of
          the IMP's Impact Frontiers cohort 2020/21, a practitioner community of
          impact investors innovating best-in-class impact measurement and
          monitoring (IMM) systems. More details on Impact Frontiers can be found{" "}
          <a href="https://impactfrontiers.org/" target="_blank">
            here
          </a>
          .
        </p>

        <p className='theory-print'>
          GreenArc's methodology takes into account a set of specific and relevant
          social & environmental indicators that are pertinent to the target
          beneficiaries. These data points are mapped and collected on each
          investment, and reflect key impact themes such as income levels, gender
          equality, labour practices, financial inclusivity and accessibility,
          environmental factors and access to basic services.
        </p>

        <p className='theory-print'>
          Performance data is collected against each metric, and the portfolio is
          allocated an ex-ante impact score based on each investment's alignment
          with how we assess expected impact, it's comparability relative to
          national socio-economic benchmarks where appropriate and contribution to
          stated social & environmental objectives.
        </p>
      </div>

     <div className='row page-divide-after'>
         <h6 className="theory-print">
          <b>GreenArc Impact Score</b>
        </h6>
      <p className='theory-print'>
        Through its impact measurement framework, GreenArc assigns a GreenArc
        Impact Score to all loans based on the potential impact of the financing
        on the beneficiaries livelihood. An impact score provides a framework
        for comparison and consistency for decision making, provides a rating on
        the capacity of an investment to generate positive impact. It helps us
        to understand where and how impact is created, as well as to prioritise
        investments based on expected impact.
      </p>

      <p className='theory-print'>
        GreenArc's impact scorecard reflects a weighted sum of indicators that
        collectively cover three aspects of impact: <b>social</b>,{" "}
        <b>economic</b> and <b>governance</b>.
      </p>

      <p className='theory-print'>
        Overall, the scorecard has approximately 15-30 indicators across 5 broad
        categories, the scores of which are combined together with a maximum
        score potential of 10 (highest impact), As such, a loan with a
        comparatively higher score will have a greater chance of generating
        positive impact than a loan with a comparatively lower score.
      </p>
      <hr className='horizontal-line'/>
     </div>

     <div className='page-divide-before'>
        <h6 className="mb-4 theory-print">
          <b>Country-Level Socio-Economic Benchmarks</b>
        </h6>
          <div className='col-md-12'>
          <div className="card">
              <div className="card-header"><h6 className='theory-card-header'><span>Socio-Economic Benchmarks</span></h6></div>
              <div className="card-body custom-scrollbar" style={{overflowY: 'auto',height:'350px'}}>
              <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Population living below National Poverty Line (% total population) ​<br/><span className="fs-11 text-muted">Source: U.S. Census​ Bureau, 2022</span></td>
                  <td>11.50%​</td>
                </tr>
                <tr>
                  <td>Rural population ​(% of total population)​<br/><span className="fs-11 text-muted">Source: U.S. Census Bureau, 2023​</span></td>
                  <td>20.0%</td>
                </tr>
                <tr>
                  <td>Female Population ​(% of total population)​<br/><span className="fs-11 text-muted">Source: World Bank, 2022​</span></td>
                  <td>50.50%​</td>
                </tr>
                <tr>
                  <td>African American Population ​(% of total population)​<br/><span className="fs-11 text-muted">Source: U.S. Department of Health & Human Services, Office of Minority Health, 2021​</span></td>
                  <td>12.1%​</td>
                </tr>
                <tr>
                  <td>Classification of Rural Areas (Criteria required for a region to be classified as 'Rural')​<br/><span className="fs-11 text-muted">Source: U.S. Department of Agriculture, 2024​</span></td>
                  <td>Housing Units: ​&lt;2,000​<br/>Residents: &lt;5,000​</td>
                </tr>
            </tbody>
            </table>
            </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
          <div className="card">
              <div className="card-header"><h6 className='theory-card-header'><span>Socio-Economic Benchmarks</span></h6></div>
              <div className="card-body custom-scrollbar" style={{height:'250px'}}>
              <div className="table-responsive">
            <table className="table table-bordered" style={{textAlign:'center'}}>
              <thead>
                <tr >
                <th>State</th>
                <th>Source</th>
                <th>Average household size</th>
                <th>Middle-class income range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tennessee​</td>
                  <td rowSpan={4}><br/>
                    <ul className='benchmark-source'>
                      <li>1) World Population Review, 2024 </li>
                      <li>2) U.S. Census Bureau, 2022​​ </li>
                    </ul>​
                  </td>
                  <td>2.51</td>
                  <td>$42,690 to $128,070​</td>
                </tr>
                <tr>
                  <td>South Carolina​</td>
                  <td>2.53</td>
                  <td>$42,415 to $127,246​</td>
                </tr>
                <tr>
                  <td>Louisiana​​</td>
                  <td>2.59</td>
                  <td>$38,568 to $115,704​​</td>
                </tr>
                <tr>
                  <td>Alabama​</td>
                  <td>2.53</td>
                  <td>$39,739 to $119,218​</td>
                </tr>
            </tbody>
            </table>
            </div>
              </div>
            </div>
          </div>
      </div> 
     
      <div>
        <h6 className="">
          <b>Glossary</b>
        </h6>
        <p>
          With the announcement of the UN Sustainable Development Goals in 2015,
          a framework has been provided for both asset allocators and investors
          to identify and align themselves with key social and/or environmental
          goals.
        </p>
        <p>
          To help investors prioritise their social preferences, we map our
          investments across impact themes and the SDGs.
        </p>

        <div className="card unsdgs-card mb-3">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6 className='theory-card-header'>
                  <span className="">UN SDGs</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <div className="row">
              <div className="col-6">
                <div className="row mb-3">
                  <div className="col-2">
                    <img
                      src="/sdg-images/sdg1.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Ensure all men and women, particularly the poor and
                      vulnerable, have equal access to economic resources and
                      basic services
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 ">
                    <img
                      src="/sdg-images/sdg5.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      End all forms of discrimination against women and girls
                    </p>
                  </div>
                </div>
                
              </div>

              <div className="col-6">
                <div className="row mb-3">
                  <div className="col-2 ">
                    <img
                      src="/sdg-images/sdg10.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Empower and promote the social, economic and political
                      inclusion of all
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="card unsdgs-card mb-3 page-divide-before">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6 className='theory-card-header'>
                  <span className="">Impact Indicators</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
          <div className="table-responsive">
            <table className="table economic-benchmarks">
              <thead>
                <tr>
                  <th>Indicator/Metric</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody id="impact-indicators">
                <tr>
                  <td>
                    <strong>Average interest rate</strong>
                  </td>
                  <td>
                    Average interest rate paid across loans in the portfolio.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Average loan tenor</strong>
                  </td>
                  <td>Average term of loans.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Average size of loans</strong>
                  </td>
                  <td>Average size of loans, in USD.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Default rate</strong>
                  </td>
                  <td>Proportion of loans that have been written off.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Total financing provided</strong>
                  </td>
                  <td>
                    Total amount, in USD, of credit loans extended to non-profits.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of racial minorities</strong>
                  </td>
                  <td>
                  Proportion of borrowers that belong to a racial minority group(Africans-Americans).
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of female borrowers</strong>
                  </td>
                  <td>
                    Proportion of borrowers that are female.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Number of loans disbursed</strong>
                  </td>
                  <td>
                   Number of loans disbursed to the borrowers.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of rural borrowers</strong>
                  </td>
                  <td>
                    Proportion of borrowers residing in rural areas of the United States.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of unsecured loans</strong>
                  </td>
                  <td>Proportion of loans issued without requiring collateral.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of repeat borrowers</strong>
                  </td>
                  <td>
                   Proportion of number of loans to non-profits who have received a previous loan from BetterFi.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of new borrowers</strong>
                  </td>
                  <td>
                   Proportion of borrowers that were not a BetterFi beneficiary previously.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Financing deployed for unplanned necessary expenses</strong>
                  </td>
                  <td>
                   Loans for unexpected expenses like car repairs, providing financing relief in unforeseen circumstances.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Consumer/Discretionary Financing</strong>
                  </td>
                  <td>
                  Financing for consumer or discretionary purposes, offering financial flexibility.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of unsecured loans</strong>
                  </td>
                  <td>
                  Proportion of loans that are unsecured. Used as a proxy for wealth/income level of recipient.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Financing deployed for debt relief</strong>
                  </td>
                  <td>
                  Loans used to help borrowers refinance personal loans and cash advances, breaking the cycle of debt.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Financing deployed for planned necessary expenses</strong>
                  </td>
                  <td>
                  Financing to help borrowers cover regular expenses such as utility bills or rent.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          </div>
        </div>

        <div className="card unsdgs-card mb-3 page-divide-before">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6 className='theory-card-header'>
                  <span className="">Ratios</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <p><span style={{fontWeight:'bold'}}>Inclusivity ratios: </span>
            <span>Measures the inclusiveness of the investment towards each beneficiary category i.e. how many clients out of the total number belongs to each beneficiary category.</span></p>

            <div className="table-responsive">
              <table className="table table-bordered impact-metrics-saas-report-table">
                  <thead>
                      <tr>
                          <td style={{backgroundColor: '#e8e8e8', width:'30%'}}><strong>Beneficiary groups</strong></td>
                          <td style={{backgroundColor: '#e8e8e8'}}><strong>Description</strong></td>
                      </tr>
                  </thead>
                  <tbody id="inclusivityTableBody">
                    <tr>
                        <td><b>Low-income Beneficiaries</b></td>
                        <td>Measures how many beneficiaries out of the total number operate on low-income levels in their state.</td>
                    </tr>
                
                    <tr>
                        <td><b>Rural Beneficiaries</b></td>
                        <td>Measures how many beneficiaries out of the total number belong to the rural regions of the US.</td>
                    </tr>
                
                    <tr>
                        <td><b>Racial Minorities</b></td>
                        <td>Measures how many beneficiaries out of the total number belong to the marginalized racial minorities groups.</td>
                    </tr>
                
                    <tr>
                        <td><b>Female Beneficiaries</b></td>
                        <td>Measures how many beneficiaries out of the total number are women.</td>
                    </tr>
                  </tbody>
                </table>
            </div>

            <p><span style={{fontWeight:"bold"}}>Accessibility ratios: </span><span>Measures the efficacy of the investment in facilitating accessibility to first time credit for each beneficiary category i.e. how many clients in each beneficiary category are first time borrowers.</span></p>

            <table className="table table-bordered impact-metrics-saas-report-table">
              <thead>
                  <tr>
                      <td style={{backgroundColor: "#e8e8e8",width:"30%"}}><strong>Beneficiary groups</strong></td>
                      <td style={{backgroundColor:"#e8e8e8"}}><strong>Description</strong></td>
                  </tr>
              </thead>
              <tbody id="accessibilityTableBody">
                <tr>
                    <td><b>Low-income Beneficiaries</b></td>
                    <td>Measures how many low-income beneficiaries get access to first time credit.</td>
                </tr>
        
                <tr>
                    <td><b>Rural Beneficiaries</b></td>
                    <td>Measures how many beneficiaries from the rural regions get access to first time credit.</td>
                </tr>
            
                <tr>
                    <td><b>Racial Minorities</b></td>
                    <td>Measures how many beneficiaries to the racial minority groups get access to first time credit. </td>
                </tr>
            
                <tr>
                    <td><b>Female Beneficiaries</b></td>
                    <td>Measures how many women get access to first time credit.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
       
        <div className="card unsdgs-card mb-3">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6 className='theory-card-header'>
                  <span className="">Scores</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <div className="table-responsive">
              <table className="table table-bordered impact-metrics-saas-report-table">
                <tbody>
                  <tr>
                    <td className="module-table-rack-info text-justify" rowSpan="8">
                      <p>
                      <span className="font-weight-bold">GreenArc Impact Score: </span><span>The GreenArc impact score is a notional measure of the overall impact that could be created from the portfolio, qualified into an ex ante score from 1-10. It is a weighted aggregation of all three components of: Beneficiary Impact, Investment Contribution and Risk.</span>
                        </p>
                    </td>
                  </tr>
                  <tr><td className="module-table-cell"><b>Beneficiary Score</b></td>
                  <td className="module-table-cell text-justify">The beneficiary score reflects the potential impact that can be created by measuring how well the portfolio is reaching its intended target beneficiaries, looking at metrics such as employment, income, and marginalized levels.</td>
                  </tr><tr>
                    <td className="module-table-cell"><b>Investment Contribution Score</b></td>
                    <td className="module-table-cell text-justify">The investment contribution score indicates to the potential of the investment to contribute to the intended impacts being sought. It reflects the additionality of the investment i.e., because of the financing provided, certain outcomes can be achieved that otherwise could not have been. </td>
                  </tr>
                  <tr>
                    <td className="module-table-cell"><b>Risk Score</b></td>
                    <td className="module-table-cell text-justify">The risk score looks at the various risks to achieving the desired impact, such as alignment risk, execution risk, efficiency risk and evidence risk. The higher the score, the lower the likelihood of said risk happening.​ </td>
                  </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='page-divide-before'>
          <h6 className="mb-4">
            <b>Author</b>
          </h6>
          <p>
            This report was prepared by GreenArc Inc, an MAS-licensed
            fintech firm headquartered in Singapore.
          </p>
          <p>
            GreenArc Inc is an impact investment fintech helping to close
            the UN SDG financing gap by increasing the flow of institutional
            capital towards social and environmental investments. We
            specialise in providing accredited investors access to private
            debt investments, with a focus on financial inclusion and climate
            action. Our technology platform uses AI tools to scale these
            impact investments through aggregation and automation, and has a
            standalone impact module to measure and report social and
            environmental impact aligned with the UN SDGs.
          </p>
        </div>
        <p>
          <i className="fa fa-globe fa-lg"></i>{" "}
          <a
            href="https://www.greenarccapital.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.greenarccapital.com/
          </a>
        </p>

        <p>
          <i className="fa fa-map-marker fa-lgsessionStorage"></i> 1 Franklin Pkwy, CA 94403, USA
        </p>
      
        <div>
          <p className="mt-4 fs-11 text-muted" style={{ marginBottom: "5rem" }}>
            <span className="text-black">Disclaimer</span>
            <br />
            <br />
            This report has been prepared by GreenArc Inc. This document is for the internal use of the recipient only. Information herein also does not constitute professional advice or recommendations regarding any securities or investments. No investment decisions should be based on the information herein. Nothing in this document constitutes accounting, legal, regulatory, tax or other advice. Although the information herein has been obtained from sources that GreenArc believes to be reliable, no representation or warranty (express or implied) is made as tothe accuracy, completeness, reliability or fairness of the information, opinions or projections herein, all of which are subject to change without notice. <br />
            <br />
            The impact scores computed in the report are based on version 2.1 of the GreenArc Impact Methodology. This methodology is subject to change based on future sector development.
          </p>
        </div>
      </div>
    </>
  );
}

export default URJDashboardTheory
