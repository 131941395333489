import React, { useState , useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/Dashboard.css'
import '../../css/projects.css';
import '../../css/print.css';
import Footer from '../../Component/Footer';
import { baseUrl } from "../../utils/urls";
import { siteCall } from '../../utils/WebCall';
import { useQuery, isEmpty, isEmptyObject } from '../../utils/common';
import { useParams } from 'react-router-dom';
import { getDashboardData, getConsolidatedDashboard } from '../../Services/DashboardService';
import DBGImpactDashboard from "../Clients/DBGImpactDashboard";
import URJImpactDashboard from "../Clients/URJImpactDashboard";

const RenderImpactDashboard = ({clientName, dashboardProps}) => {
  let ImpactDashboard = null;
  if(!isEmptyObject(dashboardProps)){
    if(['cbg', 'fidelity_bank'].includes(clientName)){
      return <DBGImpactDashboard {...dashboardProps}/>
    }else if(['betterfi'].includes(clientName)){
      return <URJImpactDashboard {...dashboardProps}/>
    }
  }
}

const GIFEDashboard = ({dashboardData, clientName}) => {
  const { projectId } = useParams();
  const [mode, setMode] = useState("single");
  const [title, setTitle] = useState("Impact Analysis");
  const [dashboardProps, setDashboardProps] = useState({});

  const query = useQuery();
  let impact_sectors = query.get('impact_sectors') || '';
  impact_sectors = impact_sectors.split(',');

  useEffect(() => {
      if(projectId){
        setMode("single")
      }
  }, []);

  useEffect(() => {
    setDashboardProps({
      dashboardData: dashboardData,
      projectId: projectId,
      title: title,
      mode: mode,
      client_names: [],
    })
  }, [dashboardData, clientName]);

  return (
    <>
      <div className="container-fluid card-impact-report">
        <div className="row logo-dropdown horizontal-line">
          <div className="col-md-6 ">
            <img src="/impactGini.png" alt="" className="img-fluid impact-gini-img"  loading="lazy" / >
          </div>
        
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="Sector" style={{display: "inline-block",fontSize: "15px"}}>Select Lender&nbsp;</label>
            <select name="impact_sector" required="required" className="form-control" id="client" style={{display: "inline-block", width: "68%", marginLeft: "1%"}}>
              <option value="all">All</option>
              <option value="cbg">CBG</option>
              <option value="fidelity_bank">Fidelity Bank</option>
              <option value="access_bank">Access Bank</option>
              <option value="sinapi_aba">Sinapi Aba</option>
              <option value="ecobank">EcoBank</option>
              <option value="calbank">CalBank</option>
            </select>
          </div>
        </div><hr className='horizontal-line'/>
        <RenderImpactDashboard clientName={clientName} dashboardProps={dashboardProps} />
      </div>
      <Footer/>
    </>
  )
  
}
export default GIFEDashboard