import React, { useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../css/Dashboard.css';

const BoxPlotChart = ({ data }) => {
  const chartRef = useRef(null);

  // Static outliers, for example purposes
  const staticOutliers = [
    { x: 'Category 1', y: 12 },
    { x: 'Category 1', y: 15 },
    { x: 'Category 2', y: 9 },
    { x: 'Category 3', y: 14 },
    { x: 'Category 3', y: 17 },
  ];

  const boxPlotData = [
    {
      name: 'Box',
      type: 'boxPlot',
      data: data.map(group => ({
        x: group.x,
        y: group.y,
      })),
    },
    // {
    //   name: 'Outliers',
    //   type: 'scatter',
    //   data: staticOutliers,   
    // },
  ];

  useEffect(() => {
    const handleBeforePrint = () => {
      const chartElement = chartRef.current.chart;
      chartElement.updateOptions({
        chart: {
          height: 250,
          width: '100%',
        },
      }, false, true);
    };

    const handleAfterPrint = () => {
      const chartElement = chartRef.current.chart;
      chartElement.updateOptions({
        chart: {
          height: 250,
          width: '100%',
        },
      }, false, true);
    };

    window.onbeforeprint = handleBeforePrint;
    window.onafterprint = handleAfterPrint;

    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);

  const options = {
    chart: {
      type: 'boxPlot',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#c0ddef',
          lower: '#c0ddef',
        },
      }
    },
    xaxis: {
      categories: data.map(group => {
        if (typeof group.x === 'string') {
          return group.x;
        } else {
          return group.x.join(' ');
        }
      }),
      labels: {
        style: {
          fontSize: '12px',
          whiteSpace: 'normal',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 10, // Adjusted to accommodate outliers
      labels: {
        formatter: function (value) {
          return value.toFixed(1);
        },
      },
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 0) { // BoxPlot series
          const dataPoint = data[dataPointIndex];
          return `
            <div class="fs-12 px-3 py-2" style='font-size:12px;'>
              <p class='mb-1' style="font-size: 12px;">
                <b>Maximum:</b> <span>${dataPoint.y[4].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>75th percentile:</b> <span>${dataPoint.y[3].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>Median:</b> <span>${dataPoint.y[2].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>25th percentile:</b> <span>${dataPoint.y[1].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>Minimum:</b> <span>${dataPoint.y[0].toFixed(1)}</span>
              </p>
            </div>
          `;
        }
         else if (seriesIndex === 1) { // Outliers series
          const outlier = staticOutliers[dataPointIndex];
          return `
            <div class="fs-12 px-3 py-2" style='font-size:12px;'>
              <p class='mb-1' style="font-size: 12px;">
                <b>Outlier:</b> <span>${outlier.y.toFixed(1)}</span>
              </p>
            </div>
          `;
        }
      }
    },
    markers: {
      size: [0, 5], // Size for box plot markers (0) and scatter markers (8)
      colors: '#c0ddef',
      strokeColors: '#000',
      strokeWidth: 1,
      shape: "circle",
      radius: 1,
      offsetX: 0,
      offsetY: 0,
      hover: {
        sizeOffset: 3
      },
    },
    legend: {
      show: false, // Hide legends
    },
  };

  return (
    <div className="box-plot-chart">
      <Chart options={options} series={boxPlotData} type="boxPlot" height="280" className="box-plot-chart-print-width" ref={chartRef} />
    </div>
  );
};

export default BoxPlotChart;
