import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const queryParams= (key) => {
 
  // Step 1: Get the current URL
  const currentUrl = window.location.href;
   
  // Step 2: Create a URL object
  const url = new URL(currentUrl);
   
  // Step 3: Use URLSearchParams to get query parameters
  const queryParams = new URLSearchParams(url.search);
   
  // Step 4: Retrieve specific query parameters
  const value = queryParams.get(key) || null;
  return value
}
 
const addToken = (headers, authorization_type) => {
  authorization_type = authorization_type || "Bearer";
  let accessToken = localStorage.getItem('accessToken') || queryParams('jwt')
  if (accessToken != null && !('Authorization' in headers)) {
    headers['Authorization'] = `${authorization_type} ${accessToken}`
  }
  return headers
}

const addContentType = (headers, value) => {
  if (!('Content-Type' in headers)) {
    headers['Content-Type'] = value
  }

  return headers
}

export const siteCall = async (config) => {
  var url = config["url"] || null;
  var method = config["method"] || 'GET';
  var body = config["body"] || {};
  var headers = config["headers"] || {};
  headers = addToken(headers);
  var successCallback = config["successCallback"] || null;
  var errorCallback = config["errorCallback"] || null;
  var responseType = config["responseType"] || null;

  headers["Access-Control-Allow-Origin"] = "*";
  headers = addContentType(headers, 'application/json');
  let axiosConfig = {
    method: method,
    url: url,
    headers: headers,
    data: body
  };
  if(responseType){
    axiosConfig["responseType"] = responseType;
  }
  console.log("axiosConfig:", axiosConfig);
  return axios(axiosConfig)
      .then(response => {
          if (successCallback) {
              return successCallback(response);
          } else {
              return response;
          }
      })
      .catch(error => {
        if (errorCallback) {
            return errorCallback(error);
        } else {
            throw error;
        }
      })
}



export const fileUploadSiteCall = async (id, url, method, body, progressBarObj, successCallback, errorCallback, headers) => {
  var method = method || 'POST'
  var body = body || {}
  var headers = headers || {};
  headers = addToken(headers)
  const { fileUploaded, setFileUploaded, index } = progressBarObj
  return axios({
    method: method,
    url: url,
    headers: headers,
    data: body,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      const percentCompleted = Math.round(
        (loaded * 100) / total
      );
      console.log("percentCompleted=", percentCompleted);
      if(Object.keys(progressBarObj).length > 0){
        let file = fileUploaded[index];
        let newFile = file;
        if (percentCompleted < 100) {
          console.log(`${loaded} bytes of ${total} bytes. ${percentCompleted}%`);
          newFile.status = "uploading";
        }else{
          newFile.status = "success";
        }
        
        // ... and set the progress
        newFile.progress = percentCompleted;
        // Create copy of files array
        let filesCopy = [...fileUploaded];
        // Find the index of the file object
        let fileIndex = filesCopy.findIndex((el) => el.name === file.name);
        //... and replace the object with the new one containing the [progress] key
        filesCopy[fileIndex] = newFile;
        // Finally, update the state
        setFileUploaded([...filesCopy]);
        file.progress = percentCompleted;
      }
    }
  })
    .then(response => {
      return successCallback(response)
    })
    .catch(error => {
      return errorCallback(error)
    })
}
