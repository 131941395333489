import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { isEmpty } from "../utils/common"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = (props) => {
  const { data } = props;
  if(!isEmpty(data)){
    data["datasets"].map((dataset) => {
      dataset.barThickness = 20;
      return dataset;
    })
  }
  // console.log("StackedBarChart:", JSON.stringify(data))
    
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const datasetLabel = context.dataset.label || '';
            const value = context.raw * 3;
            return `${datasetLabel}: ${value.toFixed(1)}`;
          },
          title: function() {
            return '';
          }
        },
        displayColors: false,
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          padding: 10,
          font: {
            size: 12, // Adjust font size here
          },
        },
        title: {
          display: true,
          font: { size: 11 },
          text: "PFIs",
          padding: { bottom: 15 } 
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 11,
          },
          padding: 10,
          stepSize: 5,
          callback: function(value) {
            return value.toFixed(1);
          }
        },
        min: 0,
        max: 10,
        title: {
          display: true,
          font: { size: 11 },
          text: "Impact Scores",
        },
      },
    },
  };
  if(!isEmpty(data)){
    return <Bar data={data} options={options} />;
  }else{
    return <></>
  }
};

export default StackedBarChart;
