import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const BeneficiaryAnalysis = ({ data, labels }) => {
  const [datasetVisibility, setDatasetVisibility] = useState(Array(data.length).fill(true));

  const chartData = {
    labels: labels.map((label, index) => {
      // const value = data[index];
      // const total = data.reduce((sum, value) => sum + value, 0);
      // const percentage = ((value / total) * 100).toFixed(1);
      return `${label}`;
    }),
    datasets: [
      {
        data: data.map((value, index) => (datasetVisibility[index] ? value : 0)),
        backgroundColor: [
          'rgb(67, 116, 153)',
          'rgb(26, 185, 231)',
          'rgb(15, 124, 180)',
          'rgb(180, 180, 180)',
          'rgb(67, 116, 153)',
          'rgb(69, 130, 161)',
        ],
        borderColor: 'rgb(255, 255, 255)',
        borderWidth: 2,
        hoverOffset: 20,
        hoverBackgroundColor: [
          'rgb(67, 116, 153)',
          'rgb(26, 185, 231)',
          'rgb(15, 124, 180)',
          'rgb(180, 180, 180)',
          'rgb(67, 116, 153)',
          'rgb(69, 130, 161)',
        ],
      },
    ],
  };

  const options = {
    radius: 100,
    cutout: 65,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        callbacks: {
          label: function (context) {
            const label = '' || '';
            return label;
          },
        },
      },
    },
    layout: {},
    
  };

  const toggleDatasetVisibility = (index) => {
    const updatedVisibility = [...datasetVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setDatasetVisibility(updatedVisibility);
  };

  const handleLegendItemClick = (index) => {
    toggleDatasetVisibility(index);
  };

  const legendItems = labels.map((label, index) => {
    const value = data[index];
    const total = data.reduce((sum, value) => sum + value, 0);
    const percentage = ((value / total) * 100).toFixed(1);

    const isHidden = !datasetVisibility[index]; 

    const legendItemStyle = {
      opacity: isHidden ? 0.5 : 1,
      fontSize: '13px', 
      lineHeight: '1.8' ,
    };
    

    return (
      <div key={label} onClick={() => handleLegendItemClick(index)} style={{legendItemStyle,display: 'flex', alignItems: 'center', marginBottom: '5px',cursor:'pointer',fontSize:'13px',wordBreak:'break-word',borderRadius:'50%'} } >
        <span
          style={{
            backgroundColor: chartData.datasets[0].backgroundColor[index],
            width: '10px',
            height: '10px',
            display: 'inline-block',
            marginRight: '10px',
            borderRadius:'50%'
          }}
        ></span>
       <span>{`${label}`}</span> 
       <span>{`${percentage}%`}</span>
      </div>
    );
  });

  return (
    <div className="donut">
      <div className="row">
        <div className="col-xl-5">
          <div className="centered-canvas-container">
            <Doughnut data={chartData} options={options}/>
          </div>
        </div>
        <div className="col-md-7 d-flex justify-content-center align-items-center">
          <div className="custom-legend">{legendItems}</div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryAnalysis;
