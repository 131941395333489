import React, { useState , useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/Dashboard.css'
import '../../css/projects.css';
import '../../css/print.css';
import BarChart from '../../Component/BarChart';
import BoxPlotChart from '../../Component/BoxPlotChart';
import FinancingbyBeneficiaryGroup from '../../Component/FinancingbyBeneficiaryGroup';
import CounterMatrix from '../../Component/CounterMatrix';
import Beneficiary from '../../Component/Beneficiary';
import LoanFinancials from '../../Component/LoanFinancials';
import CommonCard from '../../Component/CommonCard';
import Sdgs from '../../Component/Sdgs';
import ImpactScorePortfolioMetrics from '../../Component/ImpactScorePortfolioMetrics';
import PolarAreaCards from '../../Component/PolarAreaCards';
import HorizontalBarChart from '../../Component/HorizontalBarChart';
import MsgBox from '../../Component/MsgBox'
import BeneficiaryOutputSummary from '../../Component/BeneficiaryOutputSummary';
import Accordions from '../../Component/Accordion';
import AmChart from '../../Component/AmChart';
import { isEmpty, isEmptyObject } from '../../utils/common';
import StackedBarChart from '../../Component/StackedBarChart';
import Footer from '../../Component/Footer';
import URJDashboardTheory from '../../Component/Clients/DashboardTheory/URJDashboardTheory';

const addCommas = (num) => (num ? parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '');

const URJImpactDashboard = (props) => {
    const { dashboardData, projectId, title, mode, client_names, showDashboardTheory = true } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [inclusivityData, setInclusivityData] = useState([]);
    const [accessibilityData, setAccessibilityData] = useState([]);
    const [loanStatsData, setLoanStatsData] = useState([]);
    const [impactSector, setImpactSector] = useState('MSME');
    const [productFinancingData, setproductFinancingData] = useState([]);
    const [purposeOfLoan, setPurposeOfLoan] = useState([]);
    const [BeneficiaryOutputSummaryData, setBeneficiaryOutputSummaryData] = useState([]);
    const [SDGList, setSDGList] = useState([]);
    const [transformedData, setTransformedData] = useState([]);
    const [esCategorizationData, setesCategorizationData] = useState([]);
    const [msmeProportionData, setmsmeProportionData] = useState([]);
    const [impactScoreData, setimpactScoreData] = useState({});
    const [beneficiaryScoreData, setbeneficiaryScoreData] = useState([]);
    const [indicatorScores, setindicatorScores] = useState({
      contribution_indicator_score: 0,
      risk_indicator_score: 0,
      beneficiary_indicator_score: 0
    });
    const [waImpactScore, setWaImpactScore] = useState(0);
    const [impactScoresBeneficiaryGroupData, setImpactScoresBeneficiaryGroupData] = useState([]);
    const [financingByBeneficiaryGroupData, setFinancingByBeneficiaryGroupData] = useState({});
    const [beneficiaryAnalysisDonutsData, setBeneficiaryAnalysisDonutsData] = useState([]);
    const [reportText, setreportText] = useState([]);
    const [beneficiaryFinance, setbeneficiaryFinance] = useState([]);
    const [distributionScores, setDistributionScores] = useState({});

    const convertDistributionScores = (distribution_scores) => {
      distribution_scores = distribution_scores || {};
      distribution_scores?.datasets?.forEach(dataset => {
        dataset.data = dataset.data.map(value => value / 3);
      });
      setDistributionScores({...distribution_scores})
    }

    const handleApply = () => {
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);
    };
    const handlePrint = () => {
      window.print();
    };
    
    const getPlotBoxData = (data) => {
      let temp = data.map((element, index) => {
        let tempObject = {
          'x': element['name'],
          'y': [],
        };
        tempObject['y'].push(element['lower_limit']);
        tempObject['y'].push(element['q1']);
        tempObject['y'].push(element['median']);
        tempObject['y'].push(element['q3']);
        tempObject['y'].push(element['upper_limit']);

        tempObject['y'] = tempObject['y'].map((item) => {
          return parseFloat(item.toFixed(2));
        })
        return tempObject;
      })
      return temp;
    }

    const getImpactScoresBeneficiaryGroupPlotBox = (data) => {
        data = getPlotBoxData(data);
        data = data.map((element, index) => {
          element['x'] = element['x'].split(' ')
          return element;
        })
        setImpactScoresBeneficiaryGroupData(data);
    }

    const getFinancingByBeneficiaryGroupData = (data) => {
      let entries = Object.entries(data);
      entries.sort(([, valueA], [, valueB]) => valueB - valueA);
      let temp = {};
      entries.map((element) => {
        temp[element[0]] = parseInt(element[1]);
      })
      setFinancingByBeneficiaryGroupData(temp);
    }

    const renderBeneficiaryAnalysisDonuts = (stats) => {
      let gender_distribution = stats?.gender_distribution;
      let racial_distribution = stats?.racial_distribution;
      let income_level_distribution = stats?.income_level_distribution;
      let urban_rural_distribution = stats?.urban_rural_distribution;
      let state_wise_loan_distribution = stats?.state_wise_loan_distribution;

      // all donuts
      const donuts = {
        "Gender Of Beneficiaries": gender_distribution,
        "Racial Distribution Of Beneficiaries": racial_distribution,
        "Income Level Of Beneficiaries": income_level_distribution,
        "Location Of Beneficiaries": urban_rural_distribution,
        "State-Wise Distribution Of Loans": state_wise_loan_distribution,
      };

      const temp = Object.keys(donuts).map(title => ({
        title: title,
        data: donuts[title].map(item => ({
          category: item.name_string,
          value: item.value,
          color: item.color
        }))
      }));
      setBeneficiaryAnalysisDonutsData(temp);
    }

  const setStatePurposeOfLoan = (data) => {
    const purposeOfLoanData1 = [{
      title: "Purpose of Loan",
      data: data.purpose_of_loan.map(item => (
        { category: item.name_string, value: item.value, color: item.color })),  
    }]
    setPurposeOfLoan(purposeOfLoanData1)
  }

  const setStateProductFinancingData = (data) => {
    const productFinancingData1= Object.entries(data?.finance_by_loan_purpose).map(([productName, value]) => ({
      name: productName,
      value: `$${parseInt(value).toLocaleString()}`,
    }));
    setproductFinancingData(productFinancingData1);
  }

  const loadDashboard = (responseData) => {
    let loan_stats = responseData.analytics.loan_stats;
    let impact_scores = responseData.analytics.impact_scores;

    convertDistributionScores(responseData?.distribution_scores);
    const inclusivityRatioData = responseData.analytics.loan_stats.inclusivity_ratio;
    const accessibilityRatioData = responseData.analytics.loan_stats.accessibility_ratios;
    const cardData = responseData.analytics.loan_stats;
    
    const impactSector = responseData.analytics.impact_sector;
    setStatePurposeOfLoan(loan_stats)
    setStateProductFinancingData(loan_stats);
    setSDGList(responseData.sdg_details);
    getImpactScoresBeneficiaryGroupPlotBox(impact_scores?.impact_scores_beneficiary_group);
    getFinancingByBeneficiaryGroupData(loan_stats?.finance_by_beneficiary_group);
    renderBeneficiaryAnalysisDonuts(loan_stats)

    const mapFieldData = (fieldData) => {
      return fieldData?.map(item => ({
        name_string: item.name_string,
        value: item.value,
        color: item.color
      }));
    };
    
    // Extract and map the data for each specified field
    const genderOfBeneficiaryData = mapFieldData(responseData.analytics.gender_of_beneficiary);
    const sectorRatiosData = mapFieldData(responseData.analytics.sector_ratios);
    const ageOfBusinessData = mapFieldData(responseData.analytics.age_of_business);
    const jobsCreatedData = mapFieldData(responseData.analytics.jobs_created);
    const femaleJobsSupported = mapFieldData(responseData.analytics.female_jobs_supported);
    const lowCarbonClimateData = mapFieldData(responseData.analytics.low_carcon_climate_resilience);


    
    // Combine all mapped data into a single array
  const beneficiaryData = {
      "Gender of Beneficiaries": genderOfBeneficiaryData,
      "Industry Sector Analysis": sectorRatiosData,
      "Age of Businesses": ageOfBusinessData,
      "Jobs Created": jobsCreatedData,
      "Female Jobs Supported": femaleJobsSupported,
      "Low Carbon and/or Climate Resilience​": lowCarbonClimateData,
  };

  const transformedData = Object.keys(beneficiaryData).map(title => ({
    title: title,
    data: beneficiaryData[title]?.map(item => ({
      category: item.name_string,
      value: item.value,
      color: item.color
    }))
  }));
  
  const esCategorizationData = responseData.analytics.es_categorization;
  const msmeProportionData = responseData.analytics.msme_proportion;
  const impactScoreData = responseData.analytics.impact_scores.impact_score;
  const beneficiaryScoreData = responseData.analytics.impact_scores.beneficiary_score
  const indicatorScores = responseData.analytics.impact_scores.indicator_level_scores;
  const waImpactScore = (responseData.analytics.loan_stats.wa_impact_score)?.toFixed(2);
  const reportText = responseData.report_text;
  const beneficiaryFinance = responseData.analytics.finance_by_beneficiary_group

  const fieldsToExtract = [
    'female_led',
    'business_in_rural_area',
    'new_business_supported',
    'financing_for_ftb',
    'jobs_expected_to_be_created',
    'female_jobs_expected_to_be_created',
    'females_in_manegerial_roles',
    'number_of_lives_impacted'
  ];

  const BeneficiaryOutputSummaryData = {};

  function extractFields(source, fields, target) {
    fields.forEach(field => {
      if (source.hasOwnProperty(field)) {
        target[field] = source[field];
      }
    });
  }
  extractFields(responseData.analytics, fieldsToExtract, BeneficiaryOutputSummaryData);
  extractFields(responseData.analytics.loan_stats, fieldsToExtract, BeneficiaryOutputSummaryData);

  // Initialize arrays to store aggregated data
  let aggregatedDonutDataInclusivity = [];
  let aggregatedLabelsInclusivity = [];

  // Aggregate data from inclusivityRatioData
  inclusivityRatioData?.label?.forEach((label, index) => {
    aggregatedDonutDataInclusivity.push(parseFloat(inclusivityRatioData.value[index]));
    aggregatedLabelsInclusivity.push(label);
  });
  // Initialize arrays to store aggregated data
  let aggregatedDonutDataAccessibility = [];
  let aggregatedLabelsAccessibility = [];

  // Aggregate data from accessibilityRatioData
  accessibilityRatioData?.label?.forEach((label, index) => {
    let value = accessibilityRatioData.value[index];
    aggregatedDonutDataAccessibility.push(parseFloat(value));
    aggregatedLabelsAccessibility.push(label);
  });

  // Render a single PolarAreaCards component with aggregated inclusivity data
  setInclusivityData([{
    title: 'Inclusivity Ratios- Target Beneficiary Groups',
    donutData: aggregatedDonutDataInclusivity,
    labels: aggregatedLabelsInclusivity,
    popoverContent: 'Measures the inclusiveness of the investment towards each beneficiary category i.e. how many clients out of the total number belongs to each beneficiary category.'
  }]);

  // Render a single PolarAreaCards component with aggregated accessibility data
  setAccessibilityData([{
    title: 'Accessibility Ratios- First Time Credit to Beneficiary Groups',
    donutData: aggregatedDonutDataAccessibility,
    labels: aggregatedLabelsAccessibility,
    popoverContent: 'Measures the efficacy of the investment in facilitating accessibility to first time credit for each beneficiary category i.e. how many clients in each beneficiary category are first time borrowers.'
  }]);

  setImpactSector(impactSector);
  setLoanStatsData(cardData);
  setBeneficiaryOutputSummaryData(BeneficiaryOutputSummaryData);
  setTransformedData(transformedData);
  setesCategorizationData(esCategorizationData);
  setmsmeProportionData(msmeProportionData);
  setimpactScoreData(impactScoreData);
  setbeneficiaryScoreData(beneficiaryScoreData);
  setindicatorScores(indicatorScores);
  setWaImpactScore(waImpactScore);
  setreportText(reportText);
  setbeneficiaryFinance(beneficiaryFinance);
  };

  // Function to extract MSME data
  const getPortfolioSummary = (stats) => {
    return [
      {
        label: "Total financing provided",
        value: `$${addCommas(stats.total_financing)}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in USD, of credit loans extended to target population.",
      },
      {
        label: "Number of loans disbursed",
        value: `${addCommas(stats.count_loan_disbursed)}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
      {
        label: "Average size of loans",
        value: `$${addCommas(stats.simple_avg_loan_size)}`,
        popoverTitle: "Average size of loans",
        popoverContent: "Average size of loans disbursed.",
      },
    ];
  };
  const getIndividualBeneficiary = () => {
    return [
      {
        label: "Total financing provided",
        value: `$${loanStatsData.total_financing}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in USD, of credit loans extended to target population.",
      },
      {
        label: "Number of loans disbursed",
        value: `${loanStatsData.count_loan_disbursed}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
    ];
  };

  // Function to extract MSME data
  const getPortfolioFinancialsOverviewStats = (stats) => {
    return [
      {
        label: "Average interest rate",
        value: stats?.wa_interest_rate?.toFixed(2)+ '%',
        popoverTitle: "Average interest rate",
        popoverContent: "Average interest rate paid across loans in the portfolio. Shows loan affordability (risk).",
      },
      {
        label: "Average loan tenor",
        value: `${stats?.sa_loan_tenor?.toFixed(0)} months`,
        popoverTitle: "Average loan tenor",
        popoverContent: "Average term of loans, in months.",
      },
      {
        label: "Default rate",
        value: stats.loan_defaults_percent_count+ '%',
        popoverTitle: "Default rate",
        popoverContent: "Proportion of loans that have been written off.",
      },
      {
        label: "Proportion of unsecured loans",
        value: stats?.proportion_of_unsecured_loan?.toFixed(2) + '%',
        popoverTitle: "Proportion of unsecured loans",
        popoverContent: "Proportion of loans that are unsecured.",
      },
    ];
  }

  // Function to extract MSME data
  const getBeneficiaryOutputSummary = (stats) => {
    return [
        {
          label: "Number of female beneficiaries",
          value: `${addCommas(stats.number_of_female_beneficiaries)}`,
          popoverTitle: "Number of female beneficiaries",
          popoverContent: "Number of beneficiaries that are female. A higher impact score is allocated for financing directed to female beneficiaries to show gender inclusivity.",
        },
        {
          label: "Number of rural borrowers",
          value: `${addCommas(stats.number_of_rural_borrowers)}`,
          popoverTitle: "Number of rural borrowers",
          popoverContent: "Number of beneficiaries who are based in rural/remote areas.",
        },
        {
          label: "Proportion of low-income borrowers",
          value: `${stats?.proportion_of_low_income_borrowers?.toFixed(2)}%`,
          popoverTitle: "Proportion of low-income borrowers",
          popoverContent: "Proportion of beneficiaries who have low income.",
        },
        {
          label: "Proportion of racial minority (African-American population)",
          value: `${stats?.proportion_of_racial_minority?.toFixed(2)}%`,
          popoverTitle: "Proportion of racial minority (African-American population)",
          popoverContent: "Proportion of beneficiaries that are racial minorities i.e; African - American population..",
        },
        {
            label: "Proportion of repeat borrowers",
            value: `${stats?.proportion_of_repeat_borrowers?.toFixed(2)}%`,
            popoverTitle: "Proportion of repeat borrowers",
            popoverContent: "Proportion of beneficiaries who have received more than one loan from the lender.",
        },
        {
            label: "Proportion of borrowers based in low-middle income regions",
            value: `${stats?.proportion_of_low_income_regions?.toFixed(2)}%`,
            popoverTitle: "Proportion of borrowers based in low-middle income regions",
            popoverContent: "Proportion of beneficiaries living in Low Middle Income regions; often excluded populations with less access to basic goods and services including financial services.",
        },
      ]
  };

    useEffect(() => {
        if(!isEmpty(dashboardData)){
            loadDashboard(dashboardData);
        }
    }, [dashboardData]);

    // static data  
      const accessibilityDataArray = [
        {
          title: 'Accessibility Ratios- First Time Credit to Beneficiary Groups',
          labels: ['Low-Income Beneficiaries', 'Rural Beneficiaries', 'Racial Minorities','Female Beneficiaries'],
          donutData: [50, 42.9, 23.1,14.3],
          popoverContent: 'Measures the efficacy of the investment in facilitating accessibility to first time credit for each beneficiary category i.e. how many clients in each beneficiary category are first time borrowers.'
        },
      ];

  return (
    <>
        <Accordions className={'mb-1'} header= 'Impact Analytics' body={
          <div className='accordion-border' style={{border: "16px solid #dfeee2", padding: "1rem"}}>
            <div className='row'>
              <div className='col-md-3'>
              <h6 className="my-3 fs-16" style={{display: 'inline-block'}}><b>Impact Overview</b></h6>

              </div>
              <div className='col-md-6'></div>
              <div className='col-md-3 text-right'>

              <button className="btn text-dark btn-link fs-13 print-btn" style={{textDecoration:"none"}} onClick={handlePrint}><i className="fa fa-print mr-2" id="print-greenarc-portfolio-report"></i>Print / Save As PDF</button>
              </div>
            </div> <br/>
            <div className="row">
              <div className="col d-flex gap-3">
                {/* <h6 className="d-flex align-items-center mb-0"><b>Start Date:</b></h6> */}
              
              
                <DatePicker
                  className='form-control mr-2 search-input custom-datepicker'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/dd/yyyy" placeholderText='Start Date'
                />
                {/* <h6 className="d-flex align-items-center mb-0"><b>End Date:</b></h6> */}
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className='form-control ms-2 search-input custom-datepicker'
                  dateFormat="MM/dd/yyyy" placeholderText='End Date'/>
                <button className="btn btn-add-dashborad " onClick={handleApply}>
                  Apply Date Filter
                </button>
              </div>
            </div>
            
            <h6 className="mb-4 mt-4"><b>Portfolio Summary</b></h6>
            {<MsgBox initialText={reportText?.portfolio_summary || ''} id="portfolio_summary" client_names={client_names}/>}


            <div>
              {loanStatsData && (
              <>
                <CounterMatrix tabType={impactSector} data={getPortfolioSummary(loanStatsData)} />
              </>
              )}
            </div>
          
            <div className="row">
              {inclusivityData.map((item, index) => (
                <div className="col-md-6 justify-content-center" key={index}>
                  <PolarAreaCards
                    title={item.title}
                    donutData={item.donutData}
                    labels={item.labels}
                    popoverContent={item.popoverContent}
                  />
                </div>
                
              ))}
              {accessibilityData.map((item, index) => (
                <div className="col-md-6 justify-content-center" key={index}>
                  <PolarAreaCards
                    title={item.title}
                    donutData={item.donutData}
                    labels={item.labels}
                    popoverContent={item.popoverContent}
                  />
                </div>
                
              ))}
            </div>
            <hr className='horizontal-line'/>
            <h6 className="mb-4"><b>Portfolio Financials Overview</b></h6>
            {<MsgBox initialText={reportText?.portfolio_financial_overview || ''} id="portfolio_financial_overview" client_names={client_names}/>}

            <div className='page-divide-after'>
              {loanStatsData && (
              <>
                <Beneficiary tabType={impactSector} data={getPortfolioFinancialsOverviewStats(loanStatsData)} />
              </>
              )}
            </div>


            <LoanFinancials purposeOfLoanData={purposeOfLoan} productFinancingData={productFinancingData} />
            <hr className='horizontal-line'/>
            <h6 className="mb-4"><b>Investee Impact Scores and UN SDGs​​</b></h6>
            {<MsgBox initialText={reportText?.impact_scores_and_sdgs || ''} id="impact_scores_and_sdgs" client_names={client_names}/>}
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                {(waImpactScore !== null && SDGList.length > 0) ? <Sdgs chartId={`sdgChartDiv-$('-')}`} waImpactScore={waImpactScore} SDGList={SDGList}/> : 'Loading...'}
              </div>
            </div>
                
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-6'>
              <CommonCard title="Investee Impact Score Components">
                {indicatorScores && (
                  <BarChart 
                    xTitle=""
                    yTitle="Impact Scores" 
                    allData={[
                      indicatorScores.beneficiary_indicator_score,
                      indicatorScores.contribution_indicator_score,
                      indicatorScores.risk_indicator_score
                    ]} 
                    barWidth={0.23} 
                    ManualLabels={['Beneficiary Score', 'Investment Contribution Score', 'Risk Score']} showPercentage={false}
                  />
                )}
              </CommonCard>
            </div>
              <div className='col-md-4'>
              <ImpactScorePortfolioMetrics impactScoreDistribution={indicatorScores}/>
              </div>
            </div>
                
            <div className='row page-divide-after'>
            <div className='col-md-6'>
              <CommonCard title="Distribution of Individual Loan Impact Scores">
                <BarChart 
                  xTitle="Impact Scores"
                  yTitle="% of Loans"
                  allData={impactScoreData.value} 
                  barWidth={1.3} 
                  ManualLabels={impactScoreData.label}
                  xLabelsType='numeric'
                />
              </CommonCard>
            </div>
            <div className='col-md-6'>
              <CommonCard title="Distribution of Beneficiary Scores">
                <BarChart 
                  xTitle="Beneficiary Scores"
                  yTitle="% of Loans" 
                  allData={beneficiaryScoreData.value} 
                  barWidth={1.3} 
                  ManualLabels={beneficiaryScoreData.label}
                  xLabelsType='numeric'
                />
              </CommonCard>
            </div>
            </div>
            <hr className='horizontal-line'/>

            <div className='row'>
            <h6 className="mb-4"><b>Beneficiary Output Summary</b></h6>
            {<MsgBox initialText={reportText?.beneficiary_output_summary || ''} id="beneficiary_output_summary" client_names={client_names}/>}
                <div>
{BeneficiaryOutputSummaryData && (
  <>
                <BeneficiaryOutputSummary tabType={impactSector} data={getBeneficiaryOutputSummary(loanStatsData)} />
              </>
                )}
              </div>          
            </div>
            <hr className='horizontal-line'/>

            <div className="row justify-content-center page-divide-after">   
            <h6 className="mb-4"><b>Beneficiary Analysis</b></h6>
            {<MsgBox initialText={reportText?.beneficiary_analysis || ''} id="beneficiary_analysis" client_names={client_names}/>}
           
            <div className='col-md-8'>
              <div className="card" style={{height:'378px'}}>
                <div className="card-header">Impact Scores by Beneficiary Group</div>
                <div className="card-body">
                {impactScoresBeneficiaryGroupData ? (
                    <BoxPlotChart data={impactScoresBeneficiaryGroupData} />
                  ) : (
                    'Loading...'
                  )}
                </div>
              </div>
            </div>
              <div className='col-md-4'>
              {financingByBeneficiaryGroupData && (<FinancingbyBeneficiaryGroup data={financingByBeneficiaryGroupData} currency={'$'}/>
               )}
              </div>
              {/* {msme.map((item, index) => (
              <div className="col-md-6" key={index}>
                <Cards
                  title={item.title}
                  donutData={item.donutData}
                  labels={item.labels} 
                />
              </div>
              ))} */}
              

            </div>
            <div className='row page-divide-before' style={{justifyContent:"center"}}>
            <div className="col-md-12 page-divide-before">
                <div className='row' style={{justifyContent:"center"}}>
                  {beneficiaryAnalysisDonutsData.map((item, index) => (
                    <div className="col-md-6" key={index}>
                      <CommonCard title={item.title}>
                        <AmChart data={item.data} />
                      </CommonCard>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr className='horizontal-line'/>
            { showDashboardTheory == true && (
              <URJDashboardTheory tabType="MSME" /> 
            )
            }
          </div>
        }/>
    </>
  )
  
}
export default URJImpactDashboard;