import React from "react";
import Cards from "./Cards";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CommonCard from "./CommonCard";
import AmChart from "./AmChart";

const LoanFinancials = ({ purposeOfLoanData, productFinancingData }) => {
  return (
    <div>
      <div className="row">
        <div className="col-xl-6 col-md-6 col-sm-6">
          {purposeOfLoanData.map((item, index) => (
            <div className="" key={index}>
              <CommonCard title={item.title}>
                <AmChart data={item.data} />
              </CommonCard>
            </div>
          ))}
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6">
          <div className="card product-print-card-height" style={{ height: '260px' }}>
            <div className="card-header">
              <div className="custom-title">
                <span className="card-title">Product Financing Breakdown</span>
              </div>
            </div>
            <div className="card-body">
              <dl className="mb-0">
                <div className="pt-1 pb-2">
                  <div className="row mb-2">
                    <div className="col-sm-6 fs-14">
                      <strong>Product</strong>
                    </div>
                    <div className="col-sm-6 text-right fs-14">
                      <strong>Financing Deployed</strong>
                    </div>
                  </div>
                  {productFinancingData.map((product, index) => (
                    <div className="row mb-2" key={index}>
                      <div className="col-sm-6 fs-14">{product.name}</div>
                      <div className="col-sm-6 fs-14 text-right">{product.value}</div>
                    </div>
                  ))}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanFinancials;