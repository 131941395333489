import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AuthProvider from "../Providers/AuthProvider"
import routes from '../routes'

const ProtectedComponent = () => {
  return (
    <AuthProvider>
      <Routes>
          {routes.map((route, idx) => {
            return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={route.element}
                  />
                )
              )
          })}
        </Routes>
    </AuthProvider>
  )
}

export default ProtectedComponent
