
import React from 'react';

const FinancingbyBeneficiaryGroup = ({ data, currency }) => {
  currency = currency || 'GHS '
  const addCommas = (num) => (num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '');

  return (
    <div className="card" style={{ height: '378px' }}>
      <div className="card-header">
        <div className="custom-title">
          <span className="card-title">Financing by Beneficiary Group</span>
        </div>
      </div>

      <div className="card-body">
        <div className="pt-3 pb-2" id="financing_by_beneficicary_group_msme">
          {
            Object.entries(data).map(([key, value]) => {
              
              return (
                <div className="row mb-2">
                  <div className="col-sm-6 financing-table fs-14">{ key }</div>
                  <div className="col-sm-6 text-right financing-table fs-14">
                    {currency}{addCommas(value)}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default FinancingbyBeneficiaryGroup;
