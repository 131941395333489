import React from 'react';
import BarChart from './BarChart'; // Adjust the import path as necessary

const CommonCard = ({ title, children, className }) => {
  return (
    <div className="card global-card" style={{ height: '260px'}}>
      <div className='card-header'>
        {title}
      </div>
      <div className="card-body custom-scrollbar" style={{ height: 'calc(100% - 2rem)',padding: '1%' ,width:'100%'}}>
        <div className="card-content" style={{ height: '100%'}}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CommonCard;







