import React, { useState, useLayoutEffect, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import '../css/sdgs.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

am4core.useTheme(am4themes_animated);

const Sdgs = ({ waImpactScore, chartId, SDGList }) => {
  SDGList = SDGList || [];
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let chart = am4core.create(chartId, am4charts.PieChart);
    let newChartData = SDGList.map((element) => {
      element['category'] = element['name_string'];
      element['value'] = 50;
      return element;
    })
    console.log("newChartData:", newChartData)
    setChartData(newChartData);
    chart.data = newChartData;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.stroke = am4core.color("rgb(255, 255, 255)");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.tooltipText = "{category}";
    pieSeries.innerRadius = am4core.percent(100);
    pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1.1;
    pieSeries.innerRadius = am4core.percent(62);
    pieSeries.slices.template.propertyFields.fill = "fill";
    pieSeries.slices.template.propertyFields.stroke = "stroke";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.propertyFields.fill = "color";

    let label = chart.seriesContainer.createChild(am4core.Label);
    label.horizontalCenter = "top";
    label.verticalCenter = "top";
    label.fontSize = 24;
    pieSeries.tooltip.label.fontSize = 9;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    return () => {
      chart.dispose();
    };
  }, [chartId]);

  return (
    <>
      <div className='card' style={{ height: '310px' }}>
        <div className='card-header'>
          Investee Impact Score and UN SDGs​​
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose
            overlay={
              <Popover className="custom-tooltip">
                <Popover.Body>
                  <span style={{ fontSize: '13px', fontWeight: 'lighter' }}>
                    The investee impact score is a notional measure of the overall impact that could be created from the investment, qualified into an ex ante score from 1-10. It is a weighted aggregation of all three components of our impact framework; Beneficiary Impact, Investment Contribution and Risk.
                  </span>
                </Popover.Body>
              </Popover>
            }
          >
            <sup>
              <i className="fa fa-info-circle info-text fs-10 info-icon"></i>
            </sup>
          </OverlayTrigger>
        </div>
        <div className='card-body' style={{ padding: '5px' }}>
          <div className='row'>
            <div className='col-md-4'>
              <div className='centered-canvas-container sdg-canvas' style={{ height: '235px' }}>
                <div id={chartId} style={{ width: "100%", height: "100%" }} className='sdgChartDiv'></div>
                <div className="donut-text">
                  <div className="text-center" style={{ lineHeight: '1.2',position: 'relative', zIndex: '5' }}>
                    <span className="impact-score-text fs-11" style={{ display: 'block' }}>
                      GreenArc<br />Impact<br />Score
                    </span>
                    <span className="fs-15 render-impact-score" id="render_impact_score">
                      {waImpactScore}
                    </span>
                    <span className="fs-15 render-impact-score-level"></span>
                    <span className="fs-15 render-changed-indicators"> </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 d-flex align-items-center' style={{ marginTop: '8%' }}>
              <div className="section-sdgs">
                <p className="fs-12 fw-600 my-2" style={{ color: 'black' }}>
                  This product is aligned with the following UN Sustainable Development Goals:
                </p>
                {
                  chartData.map((item) => {
                    return <img className="img-sdg img-fluid" alt={item.number} src={`/sdg-images/sdg${item.number}.png`} style={{ width: '56px' }} />
                  })
                }
              </div>
            </div>
            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
              <div className="slider-container">
                <input
                  type="range"
                  className="rotated-slider"
                  min="0"
                  max="10"
                  value={10 - waImpactScore}
                  id="impact_score_range"
                  step="0.01"
                  disabled="disabled"
                />
              </div>
              <div>
                <img
                  src="/sdg-images//MicrosoftTeams-image (1).png"
                  alt=""
                  className=""
                  style={{ height: '225px', width: 'auto', loading: 'lazy' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sdgs;
