import Accordion from 'react-bootstrap/Accordion';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'; 

function Accordions({header,body, className}) {
    className = className || "";
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };
  return (
    <Accordion defaultActiveKey="0" className={className}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={toggleAccordion}>{header}
        <FontAwesomeIcon icon={isOpen ? faPlus : faMinus} />
        </Accordion.Header>
        <Accordion.Body style={{padding:'14px'}}>
         {body}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Accordions;