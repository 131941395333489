import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { columnGrid } from "../utils/columnGrid";
import "../css/Dashboard.css";
const BeneficiaryOutputSummary = ({ tabType,data }) => {
  const columnClassName = columnGrid(data);
  const individualDataBeneficiaryOutput = [
    {
      label: "Total financing provided",
      value: "US $45677",
      popoverTitle: "Total financing provided",
      popoverContent: "Total amount, in USD, of credit loans extended to target population.",
    },
    {
      label: "Number of loans disbursed",
      value: "100",
      popoverTitle: "Number of loans",
      popoverContent: "Number of loans disbursed to the target population.",
    },
  ]
  const BeneficiaryOutputSummaryData = [
    {
      label: "Number of female led/owned MSMEs",
      value: "7",
      icon: "bi bi-coin",
      popoverTitle: "Number of female led/owned MSMEs",
      popoverContent: "Number of MSMEs that are either led (CEO) or owned by a female. A higher impact score is allocated for financing directed to female led/owned MSMEs to show gender inclusivity.",
    },
    {
      label: "Number of businesses based in rural locations",
      value: "13",
      icon: "bi bi-coin",
      popoverTitle: "Number of businesses based in rural locations",
      popoverContent: "Number of beneficiaries who are based in rural/remote areas.",
    },
    {
      label: "Number of new businesses supported",
      value: "2",
      icon: "bi bi-coin",
      popoverTitle: "Number of new businesses supported",
      popoverContent: "Number of beneficiaries who are businesses less than 3 years old.",
    },
    {
      label: "Financing provided to first-time borrowers",
      value: "GHS 615,000",
      icon: "bi bi-coin",
      popoverTitle: "Financing provided to first-time borrowers",
      popoverContent: "Total amount, in local currency, of credit loans given to beneficiaries who are receiving loans for the first time in life.",
    },
    {
        label: "Total number of jobs expected to be created",
        value: "201",
        icon: "bi bi-coin",
        popoverTitle: "Total number of jobs expected to be created",
        popoverContent: "Total number of new full-time equivalent jobs expected to be created during the tenor of the loan.",
    },
    {
        label: "Total number of female jobs expected to be supported",
        value: "375",
        icon: "bi bi-coin",
        popoverTitle: "Total number of female jobs expected to be supported",
        popoverContent: "Total number of new female full-time equivalent jobs expected to be supported during the tenor of the loan.",
    },
    {
        label: "Average proportion of females in managerial roles",
        value: "34.25%",
        icon: "bi bi-coin",
        popoverTitle: "Average proportion of females in managerial roles",
        popoverContent: "The percentage of females across the management team. The more females as a proportion of management, the greater the gender inclusivity.",
    },
    {
        label: "Number of lives impacted",
        value: "361",
        icon: "bi bi-coin",
        popoverTitle: "Number of lives impacted",
        popoverContent: "Total number of employees impacted through financing the supported businesses. Shows the scale of the potential impact of credit finance.",
    },
    {
        label: "Proportion of new clients",
        value: "100.00%",
        icon: "bi bi-coin",
        popoverTitle: "Proportion of new clients",
        popoverContent: "Proportion of loans to individuals who have not received a previous loan from the financial institution i.e. new clients to the lender in question.",
    },

]

return (
  <>
    <div className="row d-flex justify-content-center">
      {data.map((item, index) => (
        <div key={index} className={index < data.length - columnClassName[1] ? columnClassName[0] : "col-md-4"}>
          <div className="card counter-matrix-card">
            <div className="card-body">
              <div className="row">
                <div className="col counter-matrix-card-col">
                  <h4 className="mb-2">{item.value}</h4>
                  <span className=" fs-13 text-sm d-block mb-2 card-number card-label-beneficiary">
                    {item.label}
                    <OverlayTrigger
                    placement="auto" trigger="click" rootClose
                    overlay={
                      <Popover id={`popover-${index}`}>
                        <Popover.Header as="h3" className='popover-header'>{item.popoverTitle}</Popover.Header>
                        <Popover.Body>
                        {item.popoverContent}
                        </Popover.Body>
                        
                      </Popover>
                    }>
                    <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>

                  </OverlayTrigger>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))} 
    </div>
    
  </>
);
};

export default BeneficiaryOutputSummary;
