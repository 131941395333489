import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import '../css/Header.css';
import { useAuth } from "../Providers/AuthProvider";
import { logoutUser } from "../Services/AuthService";
import { useNavigate } from 'react-router-dom';

const Header = ({ currentPageName }) => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const { userData } = useAuth();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("Header useEffect userData:", userData)
  }, [userData]);

  return (
    <Navbar className={`bg-color ${scrolled ? 'fixed-header' : ''}`}>
      <div className="logo-background">
        <Navbar.Brand>
          <img src="Greenarclogo.png" alt="" width="67px" height="46px" />
        </Navbar.Brand>
      </div>
      <div className="statusname ps-3">
        <h4 className="current-page">{currentPageName}</h4>
      </div>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Dropdown align="end">
          <Dropdown.Toggle variant="link" id="dropdown-basic" className="user-section no-underline no-toggle-button">
            <i className="bi bi-person-circle me-3 align-middle"></i>
            <span className="user-name">Hi, {userData?.name}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={(e)=> {
              logoutUser(navigate);
            }} className='dropdown-item'>Log Out
            <span className='pull-right'><i className="fa fa-power-off" aria-hidden="true"></i></span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
