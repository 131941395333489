import React, { useState, useEffect } from 'react';
import '../login.css';
import Navbar from 'react-bootstrap/Navbar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { loginUser, setLoggedInUser } from "../Services/AuthService";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if(email == ''){
      errors.push("Please enter email");
    }
    if(password == ''){
      errors.push("Please enter password");
    }

    if(errors.length > 0){
      errors.map((item) => {
        toast.error(item);
      })
      return false;
    }
    
    let formObject = {
      "email": email,
      "password": password,
      "login_type": "api",
    }
    loginUser(formObject).then((response) => {
        let data = response.data;  
        let token = data.token;
        let user = data.user;
        setLoggedInUser(user, token)
        navigate("/");
    }).catch((err) => {
        let errors = err.response.data;
        Object.entries(errors).map(([key, values]) => {
          if(typeof values == "string"){
            toast.error(values)
          }else if(values instanceof Array){
            values.map((value) => {
                toast.error(value)
            })
          }
        })
    });
  }

  useEffect(() => {
    // toast.error("success")
  }, []);

  return (
    <div className='main-login'>
      <Navbar className="fixed-top navbar-custom" style={{paddingBottom:'0px',paddingTop:'0px'}}>
        <div className="logo">
          <Navbar.Brand style={{paddingLeft:'15px'}}>
            <img src="Greenarclogo.png" alt="GreenArc Capital" width={85}/>
          </Navbar.Brand>
        </div>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
        </Navbar.Collapse>
      </Navbar>

      <div className="login-background" style={{paddingTop: '26%'}}>
        <div className="container login-text">
          <h4 className="fw-700 text-white">Scaling <span className="fw-800 impact">Impact</span> Investments</h4>
          <div className='row'>
            <div className="col-md-8">
            <p className="fw-400">Using AI technology and big data to advance debt financing solutions across emerging markets, we break down barriers to catalyse the sector</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{maxWidth:'1200px'}}>
        <div className="row my-5">
          <div className="col-md-6 mx-auto">
            <h3><strong>Sign in</strong></h3>
            <p className="text-muted">With your GreenArc account</p>
            <div className="login-form">
              <div className="login-body">
                <form className="mt-4" onSubmit={handleSubmit}>
                  <label className='label'>Email Address</label>
                  <div className="form-group">
                    <input 
                      autoFocus 
                      className="form-control login-email" 
                      id="email" 
                      name="email" 
                      placeholder="Enter your email address" 
                      required 
                      type="email" 
                      value={email} 
                      onChange={handleEmailChange}
                    />
                  </div>
                  <label className='label'>Password</label>
                  <div className="form-group mb-4 position-relative">
                    <input 
                      className="form-control login-pwd" 
                      id="password" 
                      name="password" 
                      placeholder="Enter your password" 
                      required 
                      type={isPasswordVisible ? 'text' : 'password'} 
                      value={password} 
                      onChange={handlePasswordChange}
                    />
                    <i 
                      className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} password-toggler`} 
                      onClick={togglePasswordVisibility} 
                      style={{ position: 'absolute', right: '10px', top: '6px', cursor: 'pointer' }}
                    ></i>
                  </div>

                  {/* {<div className="forgot-password text-right">
                    <a href="/forgot_password">Forgot password</a>
                  </div>} */}

                  <div className="form-group clearfix text-center">
                    <button type="submit" className="btn btn-login mt-4 w-100">Sign In</button>
                    {/* {<a className="btn btn-outline-login mt-1 w-100" href="/register_prelim">
                      <strong>Create your GreenArc Account</strong>
                    </a>} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="footer" className="bg-dark login-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="copyright-notice">
              © GreenArc Capital 2024. All rights reserved
              <span class="float-right">
                <a target="_blank" href="/terms_of_use" class="text-white mr-2">Terms of Use</a>
                <a target="_blank" href="/privacy_policy" class="text-white mr-2">Privacy Policy</a>
              </span>
              </p>
             
            </div>
            {/* <div className="col-md-6 col-12 d-flex justify-content-end">
              <p className="terms-privacy">
                <a target="_blank" href="/terms_of_use" className='pe-3'>Terms of Use</a>
                <a target="_blank" href="/privacy_policy">Privacy Policy</a>
              </p>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;
