import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { columnGrid } from "../utils/columnGrid";
import "../css/Dashboard.css";
const Beneficiary = ({ tabType,data }) => {
  const columnClassName = columnGrid(data);

  const individualData = [
    {
      label: "Total female loan recipients",
      value: "100",
      popoverTitle: "Total female loan recipients",
      popoverContent: "This is the total number of female loan recipients.",
    },
    {
      label: "Proportion of clients in rural/remote areas",
      value: "94%",
      popoverTitle: "Proportion of clients in rural/remote areas",
      popoverContent: "The percentage of clients in rural or remote areas.",
    },
    {
      label: "Financing for first-time borrowers",
      value: "US$12,296",
      popoverTitle: "Financing for first-time borrowers",
      popoverContent:
        "The financing provided to first-time borrowers in US dollars.",
    },
    {
      label: "Average monthly income of borrowers",
      value: "US$319",
      popoverTitle: "Average monthly income of borrowers",
      popoverContent:
        "The average monthly income of the borrowers in US dollars.",
    },
    {
      label: "Average household size",
      value: "6",
      popoverTitle: "Average household size",
      popoverContent: "The average size of households of the borrowers.",
    },
    {
      label: "Total number of lives impacted in a calender year",
      value: "517",
      popoverTitle: "Total number of lives impacted in a calender year",
      popoverContent: "Total number of lives impacted in a calender year.",
    },
    {
      label: "Number of unique lives reached since inception",
      value: "217",
      popoverTitle: "Number of unique lives reached since inception",
      popoverContent:
        "The total number of unique individuals reached since the program started.",
    },
    {
      label: "Proportion of repeat borrowers",
      value: "65%",
      popoverTitle: "Proportion of repeat borrowers",
      popoverContent:
        "The percentage of borrowers who have taken loans more than once.",
    },
  ];
  const msmeData = [
    {
      label: "Average interest rate (GHS)",
      value: "21.96%",
      icon: "bi bi-coin",
      popoverTitle: "Average interest rate (GHS)",
      popoverContent: "Average interest rate in local currency, paid across loans in the portfolio. Shows loan affordability (risk).",
    },
    {
      label: "Average loan tenor",
      value: "12 months",
      icon: "bi bi-coin",
      popoverTitle: "Average loan tenor",
      popoverContent: "Average term of loans, in months.",
    },
    {
      label: "Default rate",
      value: "0.00%",
      icon: "bi bi-coin",
      popoverTitle: "Default rate",
      popoverContent: "Proportion of loans that have been written off.",
    },
    {
      label: "Proportion of unsecured loans",
      value: "100.00%",
      icon: "bi bi-coin",
      popoverTitle: "Proportion of unsecured loans",
      popoverContent: "Proportion of loans that are unsecured.",
    },
]
 
return (
  <>
    <div className="row d-flex justify-content-center">
      {data.map((item, index) => (
        <div key={index} className={index < data.length - columnClassName[1] ? columnClassName[0] : "col-md-4"}>
          <div className="card counter-matrix-card">
            <div className="card-body">
              <div className="row">
                <div className="col counter-matrix-card-col">
                  <h4 className="mb-2">{item.value}</h4>
                  <span className="fs-13 d-block mb-2 card-number card-label-beneficiary">
                    {item.label}
                    <OverlayTrigger
                    placement="auto" trigger="click" rootClose
                    overlay={
                      <Popover id={`popover-${index}`}>
                        <Popover.Header as="h3" className='popover-header'>{item.popoverTitle}</Popover.Header>
                        <Popover.Body>
                        {item.popoverContent}
                        </Popover.Body>
                        
                      </Popover>
                    }>
                    <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>

                  </OverlayTrigger>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))} 
    </div>
    
  </>
);
};


export default Beneficiary;
