import React, { useState , useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/Dashboard.css'
import '../css/projects.css';
import '../css/print.css';
import Footer from '../Component/Footer';
import { baseUrl } from "../utils/urls";
import { siteCall } from '../utils/WebCall';
import { useQuery } from '../utils/common';
import { useParams } from 'react-router-dom';
import { getDashboardData, getConsolidatedDashboard } from '../Services/DashboardService';
import ImpactDashboard from "./ImpactDashboard";
import GIFEDashboard from  "./Dashboards/GIFEDashboard"

const Dashboard = () => {
  const { projectId } = useParams();
  const [mode, setMode] = useState("single");
  const [title, setTitle] = useState("Impact Analysis");
  const [dashboardData, setDashboardData] = useState({});
  const [clientName, setClientName] = useState('');

  const query = useQuery();
  let impact_sectors = query.get('impact_sectors') || '';
  impact_sectors = impact_sectors.split(',');

  const fetchDashboardData = async(projectId, impact_sector) => {
    await getDashboardData(projectId, impact_sector).then((response) => {
      let data = response.data
      setDashboardData(data);
      let client_name = data?.project_details?.client_name;
      setClientName(client_name)
    }).catch((error) => {

    })
  }
  useEffect(() => {
      if(projectId){
        impact_sectors.map((impact_sector) => {
          fetchDashboardData(projectId, impact_sector);
        })
        setMode("single")
      }
  }, []);

  switch (true) {
    case  ['cbg', 'fidelity_bank', 'betterfi'].includes(clientName):
      return <GIFEDashboard dashboardData={dashboardData} clientName={clientName}/>;
    default:
      return null;
  }
  
}
export default Dashboard