import React, { useState, useEffect } from 'react';
import { siteCall, queryParams } from '../utils/WebCall';
import { baseUrl } from "../utils/urls";
import { useParams } from 'react-router-dom';

const formatElementId = (elementId) => {
  return elementId
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const MsgBox = ({ initialText = '', id, client_names = [] }) => {
  const [text, setText] = useState('');
  const { projectId } = useParams();

  // Unique key for each MsgBox instance
  const element_id = `${id}`;

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSendClick = async () => {
    let requestData = {
      text: text,
      element_id: element_id,
    }

    if(projectId){
      requestData['projectId'] = projectId;
    }else{
      requestData['client_names'] = client_names;
    }
    const config = {
      url: `${baseUrl}/api/save_report_text`,
      method: 'POST',
      body: requestData,
      successCallback: (response) => {
        console.log('Text saved successfully:', response);
        // setText(''); // Clear the textarea after saving
        alert(`Text for ${formatElementId(element_id)} saved successfully!`);
      },
      errorCallback: (error) => {
        console.error('Failed to save text:', error);
        alert('Failed to save text.');
      },
    };

    await siteCall(config);
  };

  useEffect(() => {
    setText(initialText);
  }, [initialText]);
  return (
    <div className="mb-5">
      <textarea
        className="form-control custom-control"
        rows="3"
        style={{ resize: "none", boxShadow: "none" }}
        value={text}
        onChange={handleInputChange}
      ></textarea><br />
      <button className='btn btn-secondary' onClick={handleSendClick} style={{ float: 'right' }}>Save</button>
    </div>
  );
};

export default MsgBox;