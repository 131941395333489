import React from 'react'
// import { ratiosData ,scoresData } from '../utils/DashboardTheoryData'
import '../../../css/print.css';

const DashboardTheory = ({ tabType }) => {
  return (
    <>
      <div className="row">
        <h6 className="mb-4 theory-print">
          <b>Impact Score & UN SDG Contribution</b>
        </h6>

        <h6 className="mb-4 theory-print">
          <b>Financial Inclusion Impact Framework</b>
        </h6>
        <p className="theory-print">
        The following impact framework has been used to assess the impact of an Investor’s debt impact investment in the sector of financial inclusion. The framework details the mapping to the IMP’s dimensions of impact and UN SDGs, and shows a sample of indicators and target outcomes used in measuring the impact created.
        </p>
      </div>

      <div className='row'>
        <div className="table-responsive">
          <table className="table impact-framework">
            <thead>
              <tr>
                <th>IMP Dimension</th>
                <th>Theme</th>
                <th>Sample Indicators</th>
                <th>Target Outcomes</th>
                <th>UN SDGs</th>
                <th>UN SDG Targets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>WHO</td>
                <td>Poverty level</td>
                <td>
                  Income level<br></br>Employment nature<br></br>Beneficiary
                  <br></br>
                </td>
                <td>
                  Increased income<br></br>Gender equality<br></br>
                </td>
                <td rowSpan={5} className="text-center">
                  <img
                    src="/sdg-images/sdg1.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg5.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg8.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg9.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                  <img
                    src="/sdg-images/sdg10.png"
                    className="mb-2 mx-auto sdg"
                    alt="sdg"
                  />
                </td>
                <td className='border-bottom1'>1.1, 1.4</td>
              </tr>
              <tr>
                <td>HOW MUCH</td>
                <td>Social impact</td>
                <td>Level of reach</td>
                <td>Community impact</td>
                <td className='border-bottom2'>5.5, 5.7</td>
              </tr>
              <tr>
                <td>WHAT</td>
                <td>Scale (depth)</td>
                <td>Utilisation of financing</td>
                <td>Poverty alleviation</td>
                <td className='border-bottom3'>8.a, 8.3, 8.5</td>
              </tr>
              <tr>
                <td>CONTRIBUTION</td>
                <td>Additionality</td>
                <td>
                  Loan terms<br></br>Accessibility<br></br>
                </td>
                <td>
                  Job creation<br></br>Reduced inequality<br></br>
                </td>
                <td className='border-bottom4'>9.3</td>
              </tr>
              <tr>
                <td>RISK</td>
                <td>Impact risk</td>
                <td>
                  Alignment risk<br></br>Execution risk<br></br>Evidence risk
                  <br></br>
                </td>
                <td>NA</td>
                <td className='border-bottom5'>10.2, 10.3, 10.9</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
     
      <div className='row'>
        <h6 className="theory-print">
          <b>GreenArc Impact Measurement & Management</b>
        </h6>
        <p className='theory-print'>
          Our impact measurement system has fully aligned with common market
          practices, including the SDG Impact Standards, the Impact Management
          Project's (IMP) five dimensions of impact, with key indicators selected
          from GIIN’s IRIS+ catalogue of metrics and GRI. GreenArc is a member of
          the IMP's Impact Frontiers cohort 2020/21, a practitioner community of
          impact investors innovating best-in-class impact measurement and
          monitoring (IMM) systems. More details on Impact Frontiers can be found{" "}
          <a href="https://impactfrontiers.org/" target="_blank">
            here
          </a>
          .
        </p>

        <p className='theory-print'>
          GreenArc's methodology takes into account a set of specific and relevant
          social & environmental indicators that are pertinent to the target
          beneficiaries. These data points are mapped and collected on each
          investment, and reflect key impact themes such as income levels, gender
          equality, labour practices, financial inclusivity and accessibility,
          environmental factors and access to basic services.
        </p>

        <p className='theory-print'>
          Performance data is collected against each metric, and the portfolio is
          allocated an ex-ante impact score based on each investment's alignment
          with how we assess expected impact, it's comparability relative to
          national socio-economic benchmarks where appropriate and contribution to
          stated social & environmental objectives.
        </p>
      </div>

     <div className='row page-divide-after'>
         <h6 className="theory-print">
          <b>GreenArc Impact Score</b>
        </h6>
      <p className='theory-print'>
        Through its impact measurement framework, GreenArc assigns a GreenArc
        Impact Score to all loans based on the potential impact of the financing
        on the beneficiaries livelihood. An impact score provides a framework
        for comparison and consistency for decision making, provides a rating on
        the capacity of an investment to generate positive impact. It helps us
        to understand where and how impact is created, as well as to prioritise
        investments based on expected impact.
      </p>

      <p className='theory-print'>
        GreenArc's impact scorecard reflects a weighted sum of indicators that
        collectively cover three aspects of impact: <b>social</b>,{" "}
        <b>economic</b> and <b>governance</b>.
      </p>

      <p className='theory-print'>
        Overall, the scorecard has approximately 15-30 indicators across 5 broad
        categories, the scores of which are combined together with a maximum
        score potential of 10 (highest impact), As such, a loan with a
        comparatively higher score will have a greater chance of generating
        positive impact than a loan with a comparatively lower score.
      </p>
      <hr className='horizontal-line'/>
     </div>

     <div className='page-divide-before'>
        <h6 className="mb-4 theory-print">
          <b>Country-Level Socio-Economic Benchmarks</b>
        </h6>
        <div className='row'>
          <div className='col-md-6'>
            <div className="card">
              <div className="card-header">Economic Benchmarks</div>
              <div className="card-body custom-scrollbar" style={{overflowY: 'auto',height:'350px'}}>
              <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Country-level income group, Ghana<br/><span className="fs-11 text-muted">Source: World Bank, 2022</span></td>
                  <td>Lower middle-income</td>
                </tr>
                <tr>
                  <td>International monthly poverty line for lower middle-income countries<br/><span className="fs-11 text-muted">Source: Source: World Bank, 2017</span></td>
                  <td>US$206</td>
                </tr>
                <tr>
                  <td>Monthly Living Wage, Ghana<br/><span className="fs-11 text-muted">Source: Global Living Wage Coalition, 2023</span></td>
                  <td>US$266</td>
                </tr>
                <tr>
                  <td>Monthly Minimum Wage, Ghana<br/><span className="fs-11 text-muted">Source: Ministry for Employment &amp; Social Welfare, Ghana, 2023</span></td>
                  <td>US$39</td>
                </tr>
                <tr>
                  <td>Employment growth rate, Ghana<br/><span className="fs-11 text-muted">Source: World Bank, 2023</span></td>
                  <td>4.3%</td>
                </tr>
                <tr>
                  <td>Average Commercial Banks Lending Rate, Ghana<br/><span className="fs-11 text-muted">Source: BOG (Central Bank, Ghana), 2023</span>
                  </td>
                  <td>31.6%</td>
                </tr>
                <tr>
                  <td>Average Commercial Banks NPL ratio, Ghana<br/><span className="fs-11 text-muted">Source: Fitch Ratings, 2020</span></td>
                  <td>14.8%</td>
                </tr>
                <tr>
                  <td>Micro Definition (Revenue), Ghana<br/><span className="fs-11 text-muted">Source: Ghana Business Consultations Portal, National MSME and Entrepreneurship Policy, 2021</span></td>
                  <td>Rev &lt;= US$12K</td>
                </tr>
                <tr>
                  <td>Small Definition (Revenue), Ghana<br/><span className="fs-11 text-muted">Source: Ghana Business Consultations Portal, National MSME and Entrepreneurship Policy, 2021</span></td>
                  <td>US$12K &lt; Rev &lt;= US$481.9K</td>
                </tr>
                <tr>
                  <td>Medium Definition (Revenue), Ghana <br/><span className="fs-11 text-muted">Source: Ghana Business Consultations Portal, National MSME and Entrepreneurship Policy, 2021</span></td>
                  <td>US$481.9K &lt; Rev &lt;= US$1.5M</td>
                </tr>
            </tbody>
            </table>
            </div>
              </div>
            </div>
          </div>
          
          <div className='col-md-6'>
          <div className="card">
              <div className="card-header">Social Benchmarks</div>
              <div className="card-body custom-scrollbar" style={{overflowY: 'auto',height:'350px'}}>
              <div className="table-responsive">
              <table classame="table table-bordered">
                <tbody>
                  <tr>
                    <td>Proportion of population below poverty line, Ghana<br/><span className="fs-11 text-muted">Source: World bank, 2022</span></td>
                    <td>27.0%</td>
                  </tr>
                  <tr>
                    <td>Rural population, Ghana<br/><span className="fs-11 text-muted">Source: World bank, 2022</span></td>
                    <td>41.4%</td>
                  </tr>
                  <tr>
                    <td>Proportion of adults with formal credit history, Ghana<br/><span className="fs-11 text-muted">Source: World Bank, 2019</span></td>
                    <td>33.2%</td>
                  </tr>
                  <tr>
                    <td>Proportion of female workforce informally employed, Ghana<br/><span className="fs-11 text-muted">Source: ILO, 2021</span></td>
                    <td>78.2%</td>
                  </tr>
                  <tr>
                    <td>Proportion of male workforce informally employed, Ghana<br/><span className="fs-11 text-muted">Source: ILO, 2021</span></td>
                    <td>62.5%</td>
                  </tr>
                  <tr>
                    <td>Labour force participation of women, Ghana<br/><span className="fs-11 text-muted">Source: World Bank, 2022</span></td>
                    <td>65.3%</td>
                  </tr>
                  <tr>
                    <td>Proportion of women owned/led businesses, Ghana<br/><span className="fs-11 text-muted">Source: Development Bank Ghana, 2023</span></td>
                    <td>44.0%</td>
                  </tr>
                  <tr>
                    <td>Proportion of micro businesses, Ghana <br/><span className="fs-11 text-muted">Source:  Ghana Business Consultations Portal, 2019</span></td>
                    <td>81.0%</td>
                  </tr>
                  <tr>
                    <td>Proportion of women with an account at a financial institution or mobile-money-service provider (%), Ghana<br/><span className="fs-11 text-muted">Source: Global Findex Database, World Bank, 2021</span></td>
                    <td>62.6%</td>
                  </tr>
                  <tr>
                    <td>Proportion of adults (men &amp; women) with an account at a financial institution or mobile-money-service provider (%), Ghana<br/><span className="fs-11 text-muted">Source: Global Findex Database, World Bank, 2021</span></td>
                    <td>68.2%</td>
                  </tr>
                </tbody>
            </table>
            </div>
              </div>
          </div>    
        </div>
        </div>
      </div> 
     
      <div>
        <h6 className="">
          <b>Glossary</b>
        </h6>
        <p>
          With the announcement of the UN Sustainable Development Goals in 2015,
          a framework has been provided for both asset allocators and investors
          to identify and align themselves with key social and/or environmental
          goals.
        </p>
        <p>
          To help investors prioritise their social preferences, we map our
          investments across impact themes and the SDGs.
        </p>

        <div className="card unsdgs-card mb-3">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6>
                  <span className="">UN SDGs</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <div className="row">
              <div className="col-6">
                <div className="row mb-3">
                  <div className="col-2">
                    <img
                      src="/sdg-images/sdg1.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Ensure all men and women, particularly the poor and
                      vulnerable,have equal access to economic resources and
                      basic services
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 ">
                    <img
                      src="/sdg-images/sdg5.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      End all forms of discrimination against women and girls
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 ">
                    <img
                      src="/sdg-images/sdg8.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Promote sustained, inclusive and sustainable economic
                      growth, full and productive employment, and decent work
                      for all
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row mb-3">
                  <div className="col-2">
                    <img
                      src="/sdg-images/sdg9.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Promote inclusive and sustainable industrialisation, and
                      foster innovation
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 ">
                    <img
                      src="/sdg-images/sdg10.png"
                      className="mb-2 mx-auto sdg"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p className="mb-0">
                      Empower and promote the social, economic and political
                      inclusion of all
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="card unsdgs-card mb-3 page-divide-before">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6>
                  <span className="">Impact Indicators</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
          <div className="table-responsive">
            <table className="table economic-benchmarks">
              <thead>
                <tr>
                  <th>Indicator/Metric</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody id="impact-indicators">
                <tr>
                  <td>
                    <strong>Average household size</strong>
                  </td>
                  <td>
                    Average number of family members dependent on beneficiaries'
                    income.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Average interest rate</strong>
                  </td>
                  <td>
                    Average interest rate paid across loans in the portfolio.
                    Shows loan affordability (risk).
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Average loan tenor</strong>
                  </td>
                  <td>Average term of loans, in months.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Average monthly income of borrowers</strong>
                  </td>
                  <td>Average monthly income of borrowers, in USD.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Average size of loans</strong>
                  </td>
                  <td>Average size of loans, in USD.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Default rate</strong>
                  </td>
                  <td>Proportion of loans that have been written off.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Financing for first-time borrowers</strong>
                  </td>
                  <td>
                    Total amount (sum), in USD, of credit loans given to
                    beneficiaries who are receiving loans for the first time;
                    the larger the number, the greater and more inclusive the
                    reach of the credit facility.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Number of unique lives reached since inception
                    </strong>
                  </td>
                  <td>
                    Total number of beneficiaries who are receiving loans for
                    the first time and their dependents who have benefitted from
                    the loans, since inception of the investment.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Number of loans disbursed</strong>
                  </td>
                  <td>
                    Total number of loans disbursed to the target population.
                    The greater the number of loans issued, the greater the
                    number of beneficiaries receiving credit finance.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of clients in rural/remote areas</strong>
                  </td>
                  <td>
                    Proportion of beneficiaries living in rural/remote areas;
                    often excluded populations with less access to basic goods
                    and services, including financial services.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total female loan recipients</strong>
                  </td>
                  <td>Total number of beneficiaries who are female.</td>
                </tr>
                <tr>
                  <td>
                    <strong>Total financing provided</strong>
                  </td>
                  <td>
                    Total amount, in USD, of credit loans extended to target
                    population.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Total number of lives impacted in a calendar year
                    </strong>
                  </td>
                  <td>
                    Total number of borrowers and their dependents who have
                    benefitted from the loans.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Proportion of repeat borrowers</strong>
                  </td>
                  <td>
                    Percentage of individuals who have received more than one
                    loan from this lender.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          </div>
        </div>

        <div className="card unsdgs-card mb-3 page-divide-before">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6>
                  <span className="">Ratios</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <p><span style={{fontWeight:'bold'}}>Inclusivity ratios: </span>
            <span>Measures the inclusiveness of the investment towards each beneficiary category i.e. how many clients out of the total number belongs to each beneficiary category.</span></p>

            <div className="table-responsive">
              <table className="table table-bordered impact-metrics-saas-report-table">
                  <thead>
                      <tr>
                          <td style={{backgroundColor: '#e8e8e8', width:'30%'}}><strong>Beneficiary groups</strong></td>
                          <td style={{backgroundColor: '#e8e8e8'}}><strong>Description</strong></td>
                      </tr>
                  </thead>
                  <tbody id="inclusivityTableBody">
                    <tr>
                        <td><b>Rural MSMEs</b></td>
                        <td>Measures how many beneficiaries out of the total number are rural based businesses.</td>
                    </tr>
                
                    <tr>
                        <td><b>Women-led/owned MSMEs</b></td>
                        <td>Measures how many beneficiaries out of the total number are women-led/owned businesses.</td>
                    </tr>
                
                    <tr>
                        <td><b>Micro/small enterprises</b></td>
                        <td>Measures how many beneficiaries out of the total number are micro/small businesses.</td>
                    </tr>
                
                    <tr>
                        <td><b>New MSMEs</b></td>
                        <td>Measures how many beneficiaries out of the total number are new business (i.e., less than 3 years in operation).</td>
                    </tr>
                  </tbody>
                </table>
            </div>

            <p><span style={{fontWeight:"bold"}}>Accessibility ratios: </span><span>Measures the efficacy of the investment in facilitating accessibility to first time credit for each beneficiary category i.e. how many clients in each beneficiary category are first time borrowers.</span></p>

            <table className="table table-bordered impact-metrics-saas-report-table">
              <thead>
                  <tr>
                      <td style={{backgroundColor: "#e8e8e8",width:"30%"}}><strong>Beneficiary groups</strong></td>
                      <td style={{backgroundColor:"#e8e8e8"}}><strong>Description</strong></td>
                  </tr>
              </thead>
              <tbody id="accessibilityTableBody">
                <tr>
                    <td><b>Rural MSMEs</b></td>
                    <td>Measures how many rural based MSMEs get access to first time credit.</td>
                </tr>
        
                <tr>
                    <td><b>Women-led/owned MSMEs</b></td>
                    <td>Measures how many women-led/owned MSMEs get access to first time credit.</td>
                </tr>
            
                <tr>
                    <td><b>Micro/small enterprises</b></td>
                    <td>Measures how many micro/small businesses get access to first time credit. </td>
                </tr>
            
                <tr>
                    <td><b>New MSMEs</b></td>
                    <td>Measures how many new business (i.e., less than 3 years in operation) get access to first time credit.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
       
        <div className="card unsdgs-card mb-3">
          <div className="card-header border-0 unsdg-card-header">
            <div className="custom-title-wrap bar-primary">
              <div className="custom-title">
                <h6>
                  <span className="">Scores</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body None">
            <div className="table-responsive">
              <table className="table table-bordered impact-metrics-saas-report-table">
                <tbody>
                  <tr>
                    <td className="module-table-rack-info text-justify" rowSpan="8">
                      <p>
                      <span className="font-weight-bold">Investee Impact Score: </span><span>The investee impact score is a notional measure of the overall impact that could be created from the investment, qualified into an ex ante score from 1-10. It is a weighted aggregation of all three components of our impact framework; Beneficiary Impact, Investment Contribution and Risk.</span>
                        </p>
                    </td>
                  </tr>
                  <tr><td className="module-table-cell"><b>Beneficiary Score</b></td>
                  <td className="module-table-cell text-justify">The beneficiary score reflects the potential impact that can be created by measuring how well the investee is reaching its intended target beneficiaries, looking at metrics such as employment, income, and marginalized levels.</td>
                  </tr><tr>
                    <td className="module-table-cell"><b>Investment Contribution Score</b></td>
                    <td className="module-table-cell text-justify">The investment contribution score indicates to the potential of the investment to contribute to the intended impacts being sought. It reflects the additionality of the investment i.e., because of the financing provided, certain outcomes can be achieved that otherwise could not have been. </td>
                  </tr>
                  <tr>
                    <td className="module-table-cell"><b>Risk Score</b></td>
                    <td className="module-table-cell text-justify">The risk score looks at the various risks to achieving the desired impact, such as alignment risk, execution risk, efficiency risk and evidence risk. The higher the score, the lower the likelihood of said risk happening.​ </td>
                  </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='page-divide-before'>
          <h6 className="mb-4">
            <b>Author</b>
          </h6>
          <p>
            This report was prepared by GreenArc Capital, an MAS-licensed
            fintech firm headquartered in Singapore.
          </p>
          <p>
            GreenArc Capital is an impact investment fintech helping to close
            the UN SDG financing gap by increasing the flow of institutional
            capital towards social and environmental investments. We
            specialise in providing accredited investors access to private
            debt investments, with a focus on financial inclusion and climate
            action. Our technology platform uses AI tools to scale these
            impact investments through aggregation and automation, and has a
            standalone impact module to measure and report social and
            environmental impact aligned with the UN SDGs.
          </p>
        </div>
        <p>
          <i className="fa fa-globe fa-lg"></i>{" "}
          <a
            href="https://www.greenarccapital.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.greenarccapital.com/
          </a>
        </p>

        <p>
          <i className="fa fa-map-marker fa-lgsessionStorage"></i>#08-01, 80
          Robinson Road, Singapore-068898
        </p>
      
        <div>
          <p className="mt-4 fs-11 text-muted" style={{ marginBottom: "5rem" }}>
            <span className="text-black">Disclaimer</span>
            <br />
            <br />
            This report has been prepared by GreenArc Capital Pte. Ltd.
            exclusively for Investor. only and contains confidential and
            proprietary information. This document is for the internal use of
            the recipient only. Information herein also does not constitute
            professional advice or recommendations regarding any securities or
            investments. No investment decisions should be based on the
            information herein. Nothing in this document constitutes accounting,
            legal, regulatory, tax or other advice. Although the information
            herein has been obtained from sources that GreenArc Capital believes
            to be reliable, no representation or warranty (express or implied)
            is made as to the accuracy, completeness, reliability or fairness of
            the information, opinions or projections herein, all of which are
            subject to change without notice. <br />
            <br />
            The impact scores computed in the report are based on version 2.1 of
            the GreenArc Impact Methodology. This methodology is subject to
            change based on future sector development.
          </p>
        </div>
      </div>
    </>
  );
}

export default DashboardTheory
