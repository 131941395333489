import React from 'react';
import { PolarArea } from 'react-chartjs-2';

const CustomLegend = ({ legendLabels, legendColors, data }) => {
  return (
    <div className="polar-legend-container">
      {legendLabels.map((label, index) => (
        <div className="legend-item" key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', fontSize: '12px', wordBreak: 'break-word', borderRadius: '50%' }}>
          <div className="legend-color" style={{ width: '10px', height: '10px', backgroundColor: legendColors[index], marginRight: '5px', borderRadius: '50%' }}></div>
          <span className="legend-label" style={{ wordBreak: 'break-word', marginRight: '9px', color: 'black' }}>{label}</span>
          <span className="legend-value" style={{ marginLeft: 'auto', color: 'black' }}>{data[index].toFixed(1)}%</span>
        </div>
      ))}
    </div>
  );
};

const PolarAreaChart = ({ data, legendLabels }) => {
  const chartData = {
    labels: legendLabels,
    datasets: [
      {
        data: data,
        backgroundColor: ["#162735", "#74aeba", "#437499", "#1ab9e7"],
      },
    ],
  };
  const legendColors = ["#162735", "#74aeba", "#437499", "#1ab9e7"];

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: true,
        callbacks: {
          title: function() {
            return ''; 
          },
          label: function(context) {
            const label = context.label || ''; 
            const value = context.raw !== undefined ? context.raw : ''; 
            return `${label} : ${value.toFixed(1)}%`;
          },
        },
        displayColors: true,
        bodyFont: {
          size: 12,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: {
          display: false,
          beginAtZero: true,
          stepSize: 20,
          min: 0,
          max: 100,
        },
      },
    },
    layout: {
      padding: 5,
    },
  };

  return (
    <div className="print-container">
      <div className="row">
        <div className="col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
          <div className="centered-canvas-container" style={{ width: '100%', height: '200px' }}>
            <PolarArea data={chartData} options={chartOptions} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 d-flex justify-content-center align-items-center" style={{padding:'0px'}}>
          <CustomLegend legendLabels={legendLabels} legendColors={legendColors} data={data} />
        </div>
      </div>
    </div>
  );
};

export default PolarAreaChart;
