import { siteCall, queryParams } from '../utils/WebCall';
import * as url from "../utils/urls";


export const getClients = async() => {
  return siteCall(
    {
      url: url.baseUrl + url.CLIENT_URL,
      method: 'GET',
    }
  )
}