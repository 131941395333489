import React from 'react';
import "bootstrap-icons/font/bootstrap-icons.css"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { columnGrid } from "../utils/columnGrid";
const CounterMatrix = ({ tabType, data }) => {
  const columnClassName = columnGrid(data);
    const individualData = [
    {
      label: "Total financing provided",
      value: "US $45677",
      popoverTitle: "Total financing provided",
      popoverContent: "Total amount, in USD, of credit loans extended to target population.",
    },
    {
      label: "Number of loans disbursed",
      value: "100",
      popoverTitle: "Number of loans",
      popoverContent: "Number of loans disbursed to the target population.",
    },
    {
      label: "Average size of loans",
      value: "US$473",
      popoverTitle: "Average size of loans",
      popoverContent: "Average size of loans in USD.",
    },
    {
      label: "Average interest rate",
      value: "US$473",
      popoverTitle: "Average interest rate",
      popoverContent: "Average interest rate paid across loans in the portfolio.",
    },
    {
      label: "Average loan tenor",
      value: "US$473",
      popoverTitle: "Average loan tenor",
      popoverContent: "Average size of loans in USD.",
    },
    {
      label: "Default rate",
      value: "US$473",
      popoverTitle: "Default rate",
      popoverContent: "Proportion of loans that have been written off.",
    },
    {
      label: "Average size of loans",
      value: "US$473",
      popoverTitle: "Average size of loans",
      popoverContent: "Average size of loans in USD.",
    },
  ];
  const msmeData = [
    {
      label: "Total financing provided",
      value: "GHS 5,975,000",
      icon: "bi bi-coin",
      popoverTitle: "Total financing provided",
      popoverContent: "Total amount, in local currency, of credit loans extended to target population.",
    },
    {
      label: "Total financing provided by Development Bank Ghana (DBG)",
      value: "GHS 5,475,000",
      icon: "bi bi-coin",
      popoverTitle: "Total financing provided by Development Bank Ghana (DBG)",
      popoverContent: "Total amount, in local currency, of credit loans extended to target population by Development Bank Ghana (DBG).",
    },
    {
      label: "Total financing provided by Participating Financial Institutions (PFI)",
      value: "GHS 500,000",
      icon: "bi bi-coin",
      popoverTitle: "Total financing provided by Participating Financial Institutions (PFI)",
      popoverContent: "Total amount, in local currency, of credit loans extended to target population by Participating Financial Institutions (PFI).",
    },
    {
      label: "Number of loans disbursed",
      value: "14",
      icon: "bi bi-coin",
      popoverTitle: "Number of loans disbursed",
      popoverContent: "Total number of businesses supported through disbursement of credit loans.",
    },
    {
      label: "Average size of loans",
      value: "GHS 426,786",
      icon: "bi bi-coin",
      popoverTitle: "Average size of loans",
      popoverContent: "Average size of loans disbursed, in local currency.",
    },
    {
      label: "Proportion of beneficiaries based in low-middle income countries",
      value: "100.00%",
      icon: "bi bi-coin",
      popoverTitle: "Proportion of beneficiaries based in low-middle income countries",
      popoverContent: "Proportion of beneficiaries living in low-middle income countries. Access to financial services in these countries has historically been low.",
    },
]
  return (
    <>
      <div className="row d-flex justify-content-center">
        {data.map((item, index) => (
          <div key={index} className={index < data.length - columnClassName[1] ? columnClassName[0] : "col-md-4"}>
            <div className="card counter-matrix-card">
              <div className="card-body">
                <div className="row">
                  <div className="col counter-matrix-card-col">
                    <h4 className="mb-2">{item.value}</h4>
                    <span className="fs-13 d-block mb-2 card-number card-label-beneficiary">
                      {item.label}
                      <OverlayTrigger
                      placement="auto" trigger="click" rootClose
                      overlay={
                        <Popover id={`popover-${index}`}>
                          <Popover.Header as="h3" className='popover-header'>{item.popoverTitle}</Popover.Header>
                          <Popover.Body>
                          {item.popoverContent}
                          </Popover.Body>
                          
                        </Popover>
                      }>
                      <sup><i className="fa fa-info-circle info-text fs-12 info-icon"></i></sup>

                    </OverlayTrigger>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))} 
      </div>
      
    </>
  );
};

export default CounterMatrix;

