import React from 'react'
import PolarAreaChart from './PolarAreaChart'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const PolarAreaCards = ({title, donutData, labels,popoverContent}) => {
  return (
    <div className="card" style={{height:'290px'}}>
      <div className="card-header"><span>{title}</span>
        <OverlayTrigger trigger="click" placement="auto" rootClose overlay={ <Popover>
                          {/* <Popover.Header as="h3" className='popover-header'>{item.popoverTitle}</Popover.Header> */}
                          <Popover.Body>
                          {popoverContent}
                          </Popover.Body>
                          </Popover>}>
            <sup><i className="fa fa-info-circle info-text fs-10 info-icon"></i></sup>
        </OverlayTrigger>
      </div>
      <div className="card-body donut-card-body">
        <PolarAreaChart data={donutData} legendLabels={labels} />
      </div>
    </div>
  )
}
export default PolarAreaCards
