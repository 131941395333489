import React, { useState } from 'react';
import '../css/projects.css';
import '../css/FileUpload.css';
import { Modal, Button, Form } from 'react-bootstrap';

function ProjectFilter() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-3">
          <input className="form-control search-input" type="text" placeholder="Search Projects" aria-label="Search Projects" />
        </div>
        <div className="col-md-3">
          <select className="form-select search-input" aria-label="Default select example">
            <option selected>Search Status</option>
            <option value="1">Completed</option>
            <option value="3">File upload</option>
          </select>
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-3 d-flex justify-content-end">
          <button className="btn btn-add" type="button" onClick={handleShowModal}>
            Add New Project
          </button>
        </div>
      </div>

      {/* Modal for adding new project */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='model-title'>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectName">
              <Form.Label className='project-label'>Project Name</Form.Label>
              <Form.Control className='search-input' type="text" placeholder="Enter project name" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button variant="outline-primary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button className='project-save' style={{background:'#31A791 !imporant'}} onClick={handleCloseModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectFilter;
