import React from 'react'
import '../css/Footer.css'

const Footer = () => {
  return (
    <footer className="sticky-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <p className="copyright-notice footer-copyright">
                        Copyright  © 2018-2024 GreenArc Capital Pte. Ltd.
                        </p>
                    </div>
                    <div className="col-md-6 col-12 d-flex justify-content-end">
                        <p className="terms-privacy footer-terms-privacy">
                        <a target="_blank" href="/terms_of_use" className='pe-3' id='terms-of-use'>Terms of Use</a>
                        <a target="_blank" href="/privacy_policy" id='privacy-policy'>Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
  )
}
export default Footer
