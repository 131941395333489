import { baseUrl, IMPACT_DASHBOARD_URL, CONSOLIDATED_DASHBOARD_URL, GET_PROJECT_SCORES_URL } from "../utils/urls";
import { siteCall } from '../utils/WebCall';


export const getDashboardData = async (projectId, impact_sector) => {
    const config = {
      url: `${baseUrl}${IMPACT_DASHBOARD_URL}?project_id=${projectId}&impact_sector=${impact_sector}`,
      method: 'GET'
    };
    return await siteCall(config);
};


export const getConsolidatedDashboard = async (client_name) => {
    client_name = client_name || '';
    const config = {
        url: `${baseUrl}${CONSOLIDATED_DASHBOARD_URL}?client_name=${client_name}`,
        method: 'GET'
    };
    return await siteCall(config);
};

export const getProjectScores = async (project_ids) => {
    const config = {
        url: `${baseUrl}${GET_PROJECT_SCORES_URL}?project_ids=${project_ids}`,
        method: 'GET'
    };
    return await siteCall(config);
};

