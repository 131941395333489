import { createContext, useContext, useEffect, useMemo, useState, React } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../Services/AuthService"
import { getUserProfile } from "../Services/UserService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const t = getToken();

  const [accessToken, setToken_] = useState(t);

  // Function to set the authentication accessToken
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  const [userData, setUserData_] = useState(t);

  // Function to set the authentication accessToken
  const setUserData = (newData) => {
    setUserData_(newData);
  };

  useEffect(() => {
    getUserProfile().then((response) => {
      setUserData(response.data)
    }).catch((error) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      navigate("/login")
    })  
  }, []);
  

  

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken')
      navigate("/login")
    }
  }, [accessToken]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      userData,
      accessToken,
      // setToken,
    }),
    [accessToken, userData]
  );
  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
