import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);

const MAX_LABEL_LENGTH = 18; // Maximum length of each label before breaking

const breakLabel = (label) => {
  const words = label.split(' ');
  let lines = [''];
  let currentLine = 0;

  words.forEach((word) => {
    if ((lines[currentLine] + ' ' + word).length <= MAX_LABEL_LENGTH) {
      lines[currentLine] += (lines[currentLine] ? ' ' : '') + word;
    } else {
      currentLine++;
      lines[currentLine] = word;
    }
  });

  return lines;
};

const BarChart = ({
  xTitle,
  yTitle,
  allData,
  barWidth,
  ManualLabels,
  xLabelsType = 'text',
  showPercentage = true, // New prop to control percentage sign display
}) => {
  const data = {
    labels:
      xLabelsType === 'text'
        ? ManualLabels.map((label) => {
            if (label.length > MAX_LABEL_LENGTH) {
              return breakLabel(label);
            } else {
              return label;
            }
          })
        : ManualLabels,
    datasets: [
      {
        label: '',
        data: allData,
        backgroundColor: '#162735',
        borderColor: '#162735',
        borderWidth: 1,
        barPercentage: barWidth,
        categoryPercentage: 0.6,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return ManualLabels[index];
          },
          label: function (context) {
            let label = '';
            if (context.parsed.x != null) {
              label += ' ';
            }
            if (context.parsed.y !== null) {
              let value = context.parsed.y.toFixed(1);
              if (xLabelsType !== 'text') {
                label += 'Proportion: ';
              }
              if (Array.isArray(label)) {
                label = label.map((line) => line.join(''));
              } else {
                label += value;
              }
              if (showPercentage) { // Conditionally add percentage sign
                label += '%';
              }
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis gridlines
        },
        title: {
          display: true,
          text: xTitle || '',
          font: {
            size: 11,
          },
          padding: {
            bottom: 5,
          },
        },
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            if (xLabelsType === 'text') {
              return breakLabel(ManualLabels[index]);
            } else {
              if (!(ManualLabels[index] % 0.5))
                return Number(ManualLabels[index]).toFixed(1);
            }
          },
          font: {
            size: 10, // Set the font size for x-axis ticks
          },
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis gridlines
        },
        title: {
          display: true,
          text: yTitle || '',
          font: {
            size: 10,
          },
        },
        beginAtZero: true,
        maxRotation: 0,
        minRotation: 0,
        fontSize: 10,
        ticks: {
          stepSize: 5,
          callback: function (value, index, values) {
            return value.toFixed(1); // Display values with one decimal place
          },
          font: {
            size: 10, // Set the font size for y-axis ticks
          },
        },
      },
    },
  };

  return (
    <div className="barchart-canvas" style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
