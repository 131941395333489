import React, { useState , useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/Dashboard.css'
import '../css/projects.css';
import '../css/print.css';
// import DashboardTheory from '../Component/DashboardTheory';
import BarChart from '../Component/BarChart';
import BoxPlotChart from '../Component/BoxPlotChart';
import FinancingbyBeneficiaryGroup from '../Component/FinancingbyBeneficiaryGroup';
import CounterMatrix from '../Component/CounterMatrix';
import Beneficiary from '../Component/Beneficiary';
import LoanFinancials from '../Component/LoanFinancials';
import CommonCard from '../Component/CommonCard';
import Sdgs from '../Component/Sdgs';
import ImpactScorePortfolioMetrics from '../Component/ImpactScorePortfolioMetrics';
import PolarAreaCards from '../Component/PolarAreaCards';
import HorizontalBarChart from '../Component/HorizontalBarChart';
import MsgBox from '../Component/MsgBox'
import BeneficiaryOutputSummary from '../Component/BeneficiaryOutputSummary';
import Accordions from '../Component/Accordion';
import AmChart from '../Component/AmChart';
import { isEmpty, isEmptyObject } from '../utils/common';
import StackedBarChart from '../Component/StackedBarChart';
import DashboardTheory from '../Component/Clients/DashboardTheory/CBGDashboardTheory';

const ImpactDashboard = (props) => {
  const { dashboardData, projectId, title, mode, client_names, showDashboardTheory = true } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [inclusivityData, setInclusivityData] = useState([]);
  const [accessibilityData, setAccessibilityData] = useState([]);
  const [loanStatsData, setLoanStatsData] = useState([]);
  const [impactSector, setImpactSector] = useState('MSME');
  const [productFinancingData, setproductFinancingData] = useState([]);
  const [purposeOfLoanData, setpurposeOfLoanData] = useState([]);
  const [BeneficiaryOutputSummaryData, setBeneficiaryOutputSummaryData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [esCategorizationData, setesCategorizationData] = useState([]);
  const [msmeProportionData, setmsmeProportionData] = useState([]);
  const [impactScoreData, setimpactScoreData] = useState([]);
  const [beneficiaryScoreData, setbeneficiaryScoreData] = useState([]);
  const [indicatorScores, setindicatorScores] = useState({
    contribution_indicator_score: 0,
    risk_indicator_score: 0,
    beneficiary_indicator_score: 0
  });
  const [waImpactScore, setwaImpactScore] = useState([]);
  const [beneficiaryImpactScore, setbeneficiaryImpactScore] = useState([]);
  const [reportText, setreportText] = useState([]);
  const [beneficiaryFinance, setbeneficiaryFinance] = useState([]);
  const [distributionScores, setDistributionScores] = useState({});

  const convertDistributionScores = (distribution_scores) => {
    distribution_scores = distribution_scores || {};
    distribution_scores?.datasets?.forEach(dataset => {
      dataset.data = dataset.data.map(value => value / 3);
    });
    setDistributionScores({...distribution_scores})
  }

  const handleApply = () => {
    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);
  };
  const handlePrint = () => {
    window.print();
  };
  const loadDashboard = (responseData) => {
    convertDistributionScores(responseData?.distribution_scores);
    const inclusivityRatioData = responseData.analytics.inclusivity_ratio;
    const accessibilityRatioData = responseData.analytics.accessibility_ratios;
    const cardData = responseData.analytics.loan_stats;
    
    const impactSector = responseData.analytics.impact_sector;
    const productFinancingBreakdown = responseData.analytics.product_financing_breakdown;
    
    const productFinancingData = Object.entries(productFinancingBreakdown).map(([productName, value]) => ({
      name: productName,
      value: `GHS ${parseInt(value).toLocaleString()}`,
    }));
    const purpose_of_loan = responseData.analytics.purpose_of_loan;
    
    const purposeOfLoanData = purpose_of_loan.map(item => ({
      title: "Purpose of Loan",
      data: [
        { category: item.name_string, value: item.value, color: item.color }
      ]
    }));


    const mapFieldData = (fieldData) => {
      return fieldData.map(item => ({
        name_string: item.name_string,
        value: item.value,
        color: item.color
      }));
    };
    
    // Extract and map the data for each specified field
    const genderOfBeneficiaryData = mapFieldData(responseData.analytics.gender_of_beneficiary);
    const sectorRatiosData = mapFieldData(responseData.analytics.sector_ratios);
    const ageOfBusinessData = mapFieldData(responseData.analytics.age_of_business);
    const jobsCreatedData = mapFieldData(responseData.analytics.jobs_created);
    const femaleJobsSupported = mapFieldData(responseData.analytics.female_jobs_supported);
    const lowCarbonClimateData = mapFieldData(responseData.analytics.low_carcon_climate_resilience);


    
    // Combine all mapped data into a single array
    const beneficiaryData = {
      "Gender of Beneficiaries": genderOfBeneficiaryData,
      "Industry Sector Analysis": sectorRatiosData,
      "Age of Businesses": ageOfBusinessData,
      "Jobs Created": jobsCreatedData,
      "Female Jobs Supported": femaleJobsSupported,
      "Low Carbon and/or Climate Resilience​": lowCarbonClimateData,
  };

  const transformedData = Object.keys(beneficiaryData).map(title => ({
    title: title,
    data: beneficiaryData[title].map(item => ({
      category: item.name_string,
      value: item.value,
      color: item.color
    }))
  }));
  
  const esCategorizationData = responseData.analytics.es_categorization;
  const msmeProportionData = responseData.analytics.msme_proportion;
  const impactScoreData = responseData.analytics.impact_score
  const beneficiaryScoreData = responseData.analytics.beneficiary_score
  const indicatorScores = responseData.analytics.loan_stats.indicator_level_scores;
  const waImpactScore = (responseData.analytics.loan_stats.wa_impact_score).toFixed(2);
  const beneficiaryImpactScore = responseData.analytics.impact_scores_beneficiary_group;
  const reportText = responseData.report_text;
  const beneficiaryFinance = responseData.analytics.finance_by_beneficiary_group

  const fieldsToExtract = [
    'female_led',
    'business_in_rural_area',
    'new_business_supported',
    'financing_for_ftb',
    'jobs_expected_to_be_created',
    'female_jobs_expected_to_be_created',
    'females_in_manegerial_roles',
    'number_of_lives_impacted'
  ];

  const BeneficiaryOutputSummaryData = {};

  function extractFields(source, fields, target) {
    fields.forEach(field => {
      if (source.hasOwnProperty(field)) {
        target[field] = source[field];
      }
    });
  }
  extractFields(responseData.analytics, fieldsToExtract, BeneficiaryOutputSummaryData);
  extractFields(responseData.analytics.loan_stats, fieldsToExtract, BeneficiaryOutputSummaryData);

  // Initialize arrays to store aggregated data
  let aggregatedDonutDataInclusivity = [];
  let aggregatedLabelsInclusivity = [];
  let aggregatedPopoverContentInclusivity = '';

  // Aggregate data from inclusivityRatioData
  inclusivityRatioData.label.forEach((label, index) => {
    aggregatedDonutDataInclusivity.push(parseFloat(inclusivityRatioData.value[index]));
    aggregatedLabelsInclusivity.push(label);
    aggregatedPopoverContentInclusivity += `${label}: ${parseFloat(inclusivityRatioData.value[index]).toFixed(2)}%, `;
  });

  // Remove the trailing comma and space from aggregatedPopoverContent
  aggregatedPopoverContentInclusivity = aggregatedPopoverContentInclusivity.slice(0, -2);

  // Initialize arrays to store aggregated data
  let aggregatedDonutDataAccessibility = [];
  let aggregatedLabelsAccessibility = [];
  let aggregatedPopoverContentAccessibility = '';

  // Aggregate data from accessibilityRatioData
  accessibilityRatioData.label.forEach((label, index) => {
    aggregatedDonutDataAccessibility.push(parseFloat(accessibilityRatioData.value[index]));
    aggregatedLabelsAccessibility.push(label);
    aggregatedPopoverContentAccessibility += `${label}: ${parseFloat(accessibilityRatioData.value[index]).toFixed(2)}%, `;
  });

  // Remove the trailing comma and space from aggregatedPopoverContent
  aggregatedPopoverContentAccessibility = aggregatedPopoverContentAccessibility.slice(0, -2);

  // Render a single PolarAreaCards component with aggregated inclusivity data
  setInclusivityData([{
    title: 'Inclusivity Ratios- Target Beneficiary Groups',
    donutData: aggregatedDonutDataInclusivity,
    labels: aggregatedLabelsInclusivity,
    popoverContent: 'Measures the inclusiveness of the investment towards each beneficiary category i.e. how many clients out of the total number belongs to each beneficiary category.'
  }]);

  // Render a single PolarAreaCards component with aggregated accessibility data
  setAccessibilityData([{
    title: 'Accessibility Ratios- First Time Credit to Beneficiary Groups',
    donutData: aggregatedDonutDataAccessibility,
    labels: aggregatedLabelsAccessibility,
    popoverContent: 'Measures the efficacy of the investment in facilitating accessibility to first time credit for each beneficiary category i.e. how many clients in each beneficiary category are first time borrowers.'
  }]);

  setImpactSector(impactSector);
  setLoanStatsData(cardData);
  setpurposeOfLoanData(purposeOfLoanData);
  setproductFinancingData(productFinancingData);
  setBeneficiaryOutputSummaryData(BeneficiaryOutputSummaryData);
  setTransformedData(transformedData);
  setesCategorizationData(esCategorizationData);
  setmsmeProportionData(msmeProportionData);
  setimpactScoreData(impactScoreData);
  setbeneficiaryScoreData(beneficiaryScoreData);
  setindicatorScores(indicatorScores);
  setwaImpactScore(waImpactScore);
  setbeneficiaryImpactScore(beneficiaryImpactScore);
  setreportText(reportText);
  setbeneficiaryFinance(beneficiaryFinance);
  };

  const getIndividualData = () => {
    return [
      {
        label: "Total financing provided",
        value: `$${loanStatsData.total_financing}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in USD, of credit loans extended to target population.",
      },
      {
        label: "Number of loans disbursed",
        value: `${loanStatsData.count_loan_disbursed}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
    ];
  };

  // Function to extract MSME data
  const getMSMEData = () => {
    const addCommas = (num) => (num ? parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '');

    return [
      {
        label: "Total financing provided",
        value: `GHS ${addCommas(loanStatsData.total_financing)}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in local currency, of credit loans extended to target population.",
      },
      {
        label: "Total financing provided by Development Bank Ghana (DBG)",
        value: `GHS ${addCommas(loanStatsData.total_dgb_finance)}`,
        popoverTitle: "Total financing provided by Development Bank Ghana (DBG)",
        popoverContent: "Total amount, in local currency, of credit loans extended to target population by Development Bank Ghana (DBG).",
      },
      {
        label: "Total financing provided by Participating Financial Institutions (PFI)",
        value: `GHS ${addCommas(loanStatsData.total_pfi_finance)}`,
        popoverTitle: "Total financing provided by Participating Financial Institutions (PFI)",
        popoverContent: "Total amount, in local currency, of credit loans extended to target population by Participating Financial Institutions (PFI).",
      },
      {
        label: "Number of loans disbursed",
        value: `${loanStatsData.count_loan_disbursed}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
      {
        label: "Average size of loans",
        value: `GHS ${addCommas(Math.round(loanStatsData.simple_avg_loan_size))}`,
        popoverTitle: "Average size of loans",
        popoverContent: "Average size of loans disbursed, in local currency.",
      },
      {
        label: "Proportion of beneficiaries based in low-middle income countries",
        value: `${loanStatsData && loanStatsData.perect_low_middle_income_countries !== undefined ? loanStatsData.perect_low_middle_income_countries.toFixed(2) + '%' : 'N/A'}`,
        popoverTitle: "Proportion of beneficiaries based in low-middle income countries",
        popoverContent: "Proportion of beneficiaries living in low-middle income countries. Access to financial services in these countries has historically been low.",
      }

    ];
  };
  const getIndividualBeneficiary = () => {
    return [
      {
        label: "Total financing provided",
        value: `$${loanStatsData.total_financing}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in USD, of credit loans extended to target population.",
      },
      {
        label: "Number of loans disbursed",
        value: `${loanStatsData.count_loan_disbursed}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
    ];
  };

  // Function to extract MSME data
  const getMSMEBeneficiary = () => {

    return [
      {
        label: "Average interest rate (GHS)",
        value: `${loanStatsData && loanStatsData.wa_interest_rate !== undefined ? loanStatsData.wa_interest_rate.toFixed(2) + '%' : 'N/A'}`,
        popoverTitle: "Average interest rate (GHS)",
        popoverContent: "Average interest rate in local currency, paid across loans in the portfolio. Shows loan affordability (risk).",
      },
      {
        label: "Average loan tenor",
        value: `${loanStatsData.sa_loan_tenor} months`,
        popoverTitle: "Average loan tenor",
        popoverContent: "Average term of loans, in months.",
      },
      {
        label: "Default rate",
        value: `${loanStatsData && loanStatsData.loan_defaults_percent_count !== undefined ? loanStatsData.loan_defaults_percent_count.toFixed(2) + '%' : 'N/A'}`,
        popoverTitle: "Default rate",
        popoverContent: "Proportion of loans that have been written off.",
      },
      {
        label: "Proportion of unsecured loans",
        value: `${loanStatsData && loanStatsData.loan_unsecured_percent_count !== undefined ? loanStatsData.loan_unsecured_percent_count.toFixed(2) + '%' : 'N/A'}`,
        popoverTitle: "Proportion of unsecured loans",
        popoverContent: "Proportion of loans that are unsecured.",
      },
    ];
  };
  const getIndividualBeneficiaryOutput = () => {
    return [
      {
        label: "Total financing provided",
        value: `$${loanStatsData.total_financing}`,
        popoverTitle: "Total financing provided",
        popoverContent: "Total amount, in USD, of credit loans extended to target population.",
      },
      {
        label: "Number of loans disbursed",
        value: `${loanStatsData.count_loan_disbursed}`,
        popoverTitle: "Number of loans",
        popoverContent: "Number of loans disbursed to the target population.",
      },
    ];
  };

  // Function to extract MSME data
  const getMSMEBeneficiaryOutput = () => {
    const addCommas = (num) => (num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '');

    return [
        {
          label: "Number of female led/owned MSMEs",
          value: `${BeneficiaryOutputSummaryData.female_led}`,
          popoverTitle: "Number of female led/owned MSMEs",
          popoverContent: "Number of MSMEs that are either led (CEO) or owned by a female. A higher impact score is allocated for financing directed to female led/owned MSMEs to show gender inclusivity.",
        },
        {
          label: "Number of businesses based in rural locations",
          value: `${BeneficiaryOutputSummaryData.business_in_rural_area}`,
          popoverTitle: "Number of businesses based in rural locations",
          popoverContent: "Number of beneficiaries who are based in rural/remote areas.",
        },
        {
          label: "Number of new businesses supported",
          value: `${BeneficiaryOutputSummaryData.new_business_supported}`,
          popoverTitle: "Number of new businesses supported",
          popoverContent: "Number of beneficiaries who are businesses less than 3 years old.",
        },
        {
          label: "Financing provided to first-time borrowers",
          value: `GHS ${addCommas(BeneficiaryOutputSummaryData.financing_for_ftb)}`,
          popoverTitle: "Financing provided to first-time borrowers",
          popoverContent: "Total amount, in local currency, of credit loans given to beneficiaries who are receiving loans for the first time in life.",
        },
        {
            label: "Total number of jobs expected to be created",
            value: `${BeneficiaryOutputSummaryData.jobs_expected_to_be_created}`,
            popoverTitle: "Total number of jobs expected to be created",
            popoverContent: "Total number of new full-time equivalent jobs expected to be created during the tenor of the loan.",
        },
        {
            label: "Total number of female jobs expected to be supported",
            value: `${BeneficiaryOutputSummaryData.female_jobs_expected_to_be_created}`,
            popoverTitle: "Total number of female jobs expected to be supported",
            popoverContent: "Total number of new female full-time equivalent jobs expected to be supported during the tenor of the loan.",
        },
        {
            label: "Average proportion of females in managerial roles",
            value: `${BeneficiaryOutputSummaryData && BeneficiaryOutputSummaryData.females_in_manegerial_roles !== undefined ? BeneficiaryOutputSummaryData.females_in_manegerial_roles.toFixed(2) + '%' : 'N/A'}`,
            popoverTitle: "Average proportion of females in managerial roles",
            popoverContent: "The percentage of females across the management team. The more females as a proportion of management, the greater the gender inclusivity.",
        },
        {
            label: "Number of lives impacted",
            value: `${BeneficiaryOutputSummaryData.number_of_lives_impacted}`,
            popoverTitle: "Number of lives impacted",
            popoverContent: "Total number of employees impacted through financing the supported businesses. Shows the scale of the potential impact of credit finance.",
        },
        {
            label: "Proportion of new clients",
            value: "100.00%",
            popoverTitle: "Proportion of new clients",
            popoverContent: "Proportion of loans to individuals who have not received a previous loan from the financial institution i.e. new clients to the lender in question.",
        },
    
      ]
  };

    useEffect(() => {
        if(!isEmpty(dashboardData)){
            loadDashboard(dashboardData);
        }
    }, [dashboardData]);

  return (
    <>
    <Accordions className={'mb-1'} header={title} body={
          <div className='accordion-border' style={{border: "16px solid #dfeee2", padding: "1rem"}}>
            <div className='row'>
              <div className='col-md-3'>
              <h6 className="my-3 fs-16" style={{display: 'inline-block'}}><b>Impact Overview</b></h6>

              </div>
              <div className='col-md-6'></div>
              <div className='col-md-3 text-right'>

              <button className="btn text-dark btn-link fs-13 print-btn" style={{textDecoration:"none"}} onClick={handlePrint}><i className="fa fa-print mr-2" id="print-greenarc-portfolio-report"></i>Print / Save As PDF</button>
              </div>
            </div> <br/>
            <div className="row">
              <div className="col d-flex gap-3">
                {/* <h6 className="d-flex align-items-center mb-0"><b>Start Date:</b></h6> */}
              
              
                <DatePicker
                  className='form-control mr-2 search-input custom-datepicker'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/dd/yyyy" placeholderText='Start Date'
                />
                {/* <h6 className="d-flex align-items-center mb-0"><b>End Date:</b></h6> */}
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className='form-control ms-2 search-input custom-datepicker'
                  dateFormat="MM/dd/yyyy" placeholderText='End Date'/>
                <button className="btn btn-add-dashborad " onClick={handleApply}>
                  Apply Date Filter
                </button>
              </div>
            </div>
            
            <h6 className="mb-4 mt-4"><b>Portfolio Summary</b></h6>
            {<MsgBox initialText={reportText?.portfolio_summary || ''} id="portfolio_summary" client_names={client_names}/>}


            <div>
              {loanStatsData && (
              <>
                <CounterMatrix tabType={impactSector} data={impactSector === 'Individual' ? getIndividualData() : getMSMEData()} />
              </>
              )}
            </div>
          
            <div className="row">
              {inclusivityData.map((item, index) => (
                <div className="col-md-6 justify-content-center" key={index}>
                  <PolarAreaCards
                    title={item.title}
                    donutData={item.donutData}
                    labels={item.labels}
                    popoverContent={item.popoverContent}
                  />
                </div>
                
              ))}
              {accessibilityData.map((item, index) => (
                <div className="col-md-6 justify-content-center" key={index}>
                  <PolarAreaCards
                    title={item.title}
                    donutData={item.donutData}
                    labels={item.labels}
                    popoverContent={item.popoverContent}
                  />
                </div>
                
              ))}
          </div>
            <hr className='horizontal-line'/>
            <h6 className="mb-4"><b>Portfolio Financials Overview</b></h6>
            {<MsgBox initialText={reportText?.portfolio_financial_overview || ''} id="portfolio_financial_overview" client_names={client_names}/>}

            <div className='page-divide-after'>
              {loanStatsData && (
              <>
                <Beneficiary tabType={impactSector} data={impactSector === 'Individual' ? getIndividualBeneficiary() : getMSMEBeneficiary()} />
              </>
              )}
            </div>


            <LoanFinancials purposeOfLoanData={purposeOfLoanData} productFinancingData={productFinancingData} />
            <hr className='horizontal-line'/>
            <h6 className="mb-4"><b>Investee Impact Scores and UN SDGs​​</b></h6>
            {<MsgBox initialText={reportText?.impact_scores_and_sdgs || ''} id="impact_scores_and_sdgs" client_names={client_names}/>}
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                {waImpactScore !== null ? <Sdgs chartId={`sdgChartDiv-${client_names.join('-')}`} waImpactScore={waImpactScore} /> : 'Loading...'}
              </div>
            </div>
                
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-6'>
              <CommonCard title="Investee Impact Score Components">
                {indicatorScores && (
                  <BarChart 
                    xTitle=""
                    yTitle="Impact Scores" 
                    allData={[
                      indicatorScores.beneficiary_indicator_score,
                      indicatorScores.contribution_indicator_score,
                      indicatorScores.risk_indicator_score
                    ]} 
                    barWidth={0.23} 
                    ManualLabels={['Beneficiary Score', 'Investment Contribution Score', 'Risk Score']} 
                  />
                )}
              </CommonCard>
            </div>
              <div className='col-md-4'>
              <ImpactScorePortfolioMetrics impactScoreDistribution={indicatorScores}/>
              </div>
            </div>
                
            <div className='row page-divide-after'>
            <div className='col-md-6'>
            { mode == "single" && 
              <CommonCard title="Distribution of Individual Loan Impact Scores">
                <BarChart 
                  xTitle="Impact Scores"
                  yTitle="% of Loans" 
                  allData={impactScoreData.value} 
                  barWidth={1.3} 
                  ManualLabels={impactScoreData.label}
                  xLabelsType='numeric'
                />
              </CommonCard>
            }
            { mode == "consolidated" && 
              <CommonCard title="Distribution of Underlying Loan Level Impact Scores
              ">
                
              <BarChart 
                xTitle="Impact Scores"
                yTitle="% of Loans" 
                allData={impactScoreData.value} 
                barWidth={1.3} 
                ManualLabels={impactScoreData.label}
                xLabelsType='numeric'
              />
            </CommonCard>
            }
            </div>
            <div className='col-md-6'>
            { mode == "single" && 
              <CommonCard title="Distribution of Beneficiary Scores">
                <BarChart 
                  xTitle="Beneficiary Scores"
                  yTitle="% of Loans" 
                  allData={beneficiaryScoreData.value} 
                  barWidth={1.3} 
                  ManualLabels={beneficiaryScoreData.label}
                  xLabelsType='numeric'
                />
              </CommonCard>
            }

            { mode == "consolidated" && 
              <CommonCard title="Distribution of PFIs Impact Scores">
                  <StackedBarChart data={distributionScores}/>
              </CommonCard>
            }
            </div>
            </div>
            <hr className='horizontal-line'/>

            <div className='row'>
            <h6 className="mb-4"><b>Beneficiary Output Summary</b></h6>
            {<MsgBox initialText={reportText?.beneficiary_output_summary || ''} id="beneficiary_output_summary" client_names={client_names}/>}
                <div>
{BeneficiaryOutputSummaryData && (
  <>
                <BeneficiaryOutputSummary tabType={impactSector} data={impactSector === 'Individual' ? getIndividualBeneficiaryOutput() : getMSMEBeneficiaryOutput()} />
              </>
                )}
              </div>          
            </div>
            <hr className='horizontal-line'/>

            <div className="row justify-content-center page-divide-after">   
            <h6 className="mb-4"><b>Beneficiary Analysis</b></h6>
            {<MsgBox initialText={reportText?.beneficiary_analysis || ''} id="beneficiary_analysis" client_names={client_names}/>}
           
            <div className='col-md-8'>
              <div className="card" style={{height:'378px'}}>
                <div className="card-header">Impact Scores by Beneficiary Group</div>
                <div className="card-body">
                {beneficiaryImpactScore ? (
                    <BoxPlotChart data={beneficiaryImpactScore} />
                  ) : (
                    'Loading...'
                  )}
                </div>
              </div>
              {/* <CommonCard title="Impact Scores by Beneficiary Group">
                {beneficiaryImpactScore ? (
                  <BoxPlotChart data={beneficiaryImpactScore} />
                ) : (
                  'Loading...'
                )}
              </CommonCard> */}
            </div>
              <div className='col-md-4'>
              {beneficiaryFinance && (<FinancingbyBeneficiaryGroup data={beneficiaryFinance} />
               )}
              </div>
              {/* {msme.map((item, index) => (
              <div className="col-md-6" key={index}>
                <Cards
                  title={item.title}
                  donutData={item.donutData}
                  labels={item.labels} 
                />
              </div>
              ))} */}
              

            </div>
            <div className='row page-divide-before' style={{justifyContent:"center"}}>
            <div className="col-md-12 page-divide-before">
                <div className='row' style={{justifyContent:"center"}}>
                  {transformedData.map((item, index) => (
                    <div className="col-md-6" key={index}>
                      <CommonCard title={item.title}>
                        <AmChart data={item.data} />
                      </CommonCard>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr className='horizontal-line'/>
          
            <div className='row page-divide-after'>
            <div className='col-md-6'>
            <CommonCard title="E&S Categorisation">
              <HorizontalBarChart 
                xTitle="% of MSMEs"
                yTitle="E & S Categorisation" 
                allData={esCategorizationData.value} 
                barWidth={0.65} 
                ManualLabels={esCategorizationData.label}
              />
            </CommonCard>
          </div>
          <div className='col-md-6'>
            <CommonCard title="Proportion of MSMEs with Required E&S Certifications">
                <HorizontalBarChart 
                xTitle="% of MSMEs"
                yTitle="Required E & S Certifications" 
                allData={msmeProportionData.value} 
                barWidth={0.45} 
                ManualLabels={msmeProportionData.label}
                />
            </CommonCard>
          </div>
            </div>
            { showDashboardTheory == true && (
              <DashboardTheory tabType="MSME" /> 
            )
            }
          </div>
        }/>
    </>
  )
  
}
export default ImpactDashboard;