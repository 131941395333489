export const columnGrid = (data) => {
    let colName = 'col-md-3';
    let remainder = 0;
    let len = data.length;
  
    if (len % 4 === 0 || len % 7 === 0) {
      colName = 'col-md-3';
    } else if (len % 3 === 0 || len === 5) {
      colName = 'col-md-4';
    }
    remainder = len % 4;
    return [colName, remainder];
  };